<template>
  <div class="">
    <div class="container_page">
      <div class="container__sidebar">
        <Sidebar />
      </div>

      <div class="content">
        <header>
          <Navbar />
        </header>
        <main>
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/layouts/Sidebar.vue';
import Navbar from '@/layouts/Navbar.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    Sidebar,
    Navbar,
  },
  name: 'dashboard',
  methods: {
    ...mapActions('app', ['setRouteName']),
  },
  mounted() {
    this.setRouteName(this.$route.name)
  },
  watch: {
    '$route'(to, from) {
      this.setRouteName(this.$route.name)
    }
  },
};
</script>
