import defaultState from '../defaultState/index.js';
import { axios, CancelToken, isCancel } from '../services/axios.js';

export default {
  namespaced: true,
  state: { ...defaultState.agent },
  mutations: {
    ADD(state, payload) {
      // this mutation will add a new record to collection
      state.collection = [payload, ...state.collection];
    },
    DELETE(state, payload) {
      // this mutation will delete record by id from collection
      state.collection = state.collection.filter(
        (record) => record.id != payload.id,
      );
    },
    UPDATE(state, payload) {
      let index = state.collection.findIndex((x) => x.id === payload.id);
      state.collection.splice(index, 1, payload);
    },
    SET_MODEL(state, payload) {
      // this mutation will change the agent object value
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        hermesId: null,
        firstName: null,
        lastName: null,
        callCenterId: null,
        teamId: null,
        roleId: null,
      };
    },
    SET_COLLECTION(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.collection = payload;
    },
    SET_FILTRED_COLLECTION(state, payload) {
      state.filtredCollection = payload;
    },
    SET_COLLECTION_TEAM(state, payload) {
      state.collection = [...state.collection, ...payload];
    },
    SET_PAGINATION(state, payload) {
      // this mutation will change the pagination state values
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_IS_FETCHING(state, payload) {
      state.isFetchingData = payload;
    },
    SET_IS_FETCHING_DETAILS(state, payload) {
      state.isFetchingDetails = payload;
    },

    SET_STAT_COLLECTION(state, payload) {
      state.statCollection = payload;
    },
    SET_MORE_DETAILS_COLLECTION(state, payload) {
      state.moreDetailsCollection = payload;
    },
    SET_TOP_FLOP_COLLECTION(state, payload) {
      state.topFlopCollection = payload;
    },
    SET_TOP_FLOP(state, payload) {
      state.model.topFlop = payload;
    },
    SET_CANCEL(state, cancel) {
      state.cancel = cancel;
    },
    RESET_STATE(state, intialState) {
      Object.keys(intialState).forEach((key) => {
        state[key] = intialState[key];
      });
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
        inTeam: false,
      };
    },
    SET_IS_IN_DASHBOARD(state, payload) {
      state.model.inDashboard = payload;
    },
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    resetState(context) {
      context.commit('RESET_STATE', defaultState.agent);
    },
    setCollection(context, payload) {
      context.commit('SET_COLLECTION', payload);
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    setIsInDashboard(context, payload) {
      context.commit('SET_IS_IN_DASHBOARD', payload);
    },


    async getData(context) {
      var inTeam = context.state.pagination.inTeam;
      
      if (inTeam == false) context.commit('SET_IS_FETCHING', true);
      return await new Promise((resolve, reject) => {
        if (context.state.cancel) {
          context.state.cancel();
        }

        const cancelSource = CancelToken.source();

        if (!context.state.model.inDashboard) {
          context.commit('SET_CANCEL', cancelSource.cancel);
        }
        const config = {
          params: {
            ...context.state.filter,
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            withPagination: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: !context.state.model.inDashboard
          ? cancelSource.token
          : null,
        };
        axios
          .get('/agent', config)
          .then(async (res) => {
            await context.commit('SET_FILTRED_COLLECTION', [])
            await context.commit('SET_FILTRED_COLLECTION', res.data.data.data)
            if (inTeam == false) {
              await context.commit('SET_COLLECTION', res.data.data.data);
            } else {
              await context.commit('SET_COLLECTION_TEAM', res.data.data.data);
            }

            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
              context.commit('SET_PAGINATION', {
                ...context.state.pagination,
                ['inTeam']: inTeam,
              });
            } else {
              await context.commit('RESET_PAGINATION');
            }
            context.commit('SET_IS_FETCHING', false);

            resolve(res.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) {
              context.commit('SET_IS_FETCHING', false);

              reject(err);
            }
          });
      });
    },
    async getDataSearched(context, payload) {
      // context.commit('SET_IS_FETCHING', true);
      return await new Promise((resolve, reject) => {
        if (context.state.cancel) {
          context.state.cancel();
        }

        const cancelSource = CancelToken.source();

        context.commit('SET_CANCEL', cancelSource.cancel);

        const config = {
          params: {
            keyword: payload,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/agent/toSearch', config)
          .then(async (res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING', false);
            if (!isCancel(err)) reject(err);
          });
      });
    },
    async createAgent(context) {
      context.state.filter.keyword = '';
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .post('/agent', context.state.model, config)
          .then((res) => {
            if (
              context.state.collection.length >=
              context.state.pagination.per_page
            ) {
              context.state.collection.splice(-1, 1),
                context.commit('ADD', res.data.data);
            } else {
              context.commit('ADD', res.data.data);
            }

            context.commit('SET_PAGINATION', res.data.pagination);

            context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(false);
          });
      });
    },
    async updateAgent(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .put('/agent/' + context.state.model.id, context.state.model, config)
          .then(async (res) => {
            await context.commit('UPDATE', res.data.data.data);
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(false);
          });
      });
    },
    async deleteAgent(context, payload) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            keyword: context.state.filter.keyword,
            withPagination: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          data: { ids: payload },
        };
        axios
          .delete('/agent/delete/' + context.state.model.id, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            //reject(err);
          });
      });
    },
    async getAgentRecordsData(context, payload) {
      context.commit('SET_IS_FETCHING_DETAILS', true);
      if (context.state.cancel) {
        context.state.cancel();
      }
      const cancelSource = CancelToken.source();
      context.commit('SET_CANCEL', cancelSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDateForCompare = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };

        const startDateForCompare = formatDateForCompare(oneWeekAgo);
        const endDateForCompare = formatDateForCompare(today);

        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            ...context.state.filter,
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDateForCompare,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDateForCompare,
            startOldDate: context.state.filter?.startOldDate
              ? context.state.filter?.startOldDate
              : startDateForCompare,
            endOldDate: context.state.filter?.endOldDate
              ? context.state.filter?.endOldDate
              : endDateForCompare,
            campagneId: payload?.dashbordData
              ? payload?.campaign.campagneId
              : payload?.campagneId,
            gridId: payload?.dashbordData
              ? payload?.campaign.gridSelected?.id
              : payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/agent/getRecordsDetails/' + payload.agentId, config)
          .then(async (res) => {
            let dataToCommit = res.data.data.data;

            // Check if dataToCommit is not an array, convert it to an array
            if (!Array.isArray(dataToCommit)) {
              dataToCommit = Object.values(dataToCommit);
            }

            await context.commit('SET_MORE_DETAILS_COLLECTION', dataToCommit);
            await context.commit('SET_IS_FETCHING_DETAILS', false);
            resolve(res.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) context.commit('SET_IS_FETCHING_DETAILS', false);

            //context.commit('SET_IS_FETCHING', false);
            //reject(err);
          });
      });
    },
    async getStatAgentsData(context, payload) {
      context.commit('SET_IS_FETCHING', true);
      if (context.state.cancel) {
        context.state.cancel();
      }
      const cancelSource = CancelToken.source();
      context.commit('SET_CANCEL', cancelSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDateForCompare = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };

        const startDateForCompare = formatDateForCompare(oneWeekAgo);
        const endDateForCompare = formatDateForCompare(today);

        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            ...context.state.filter,
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDateForCompare,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDateForCompare,
            startOldDate: context.state.filter?.startOldDate
              ? context.state.filter?.startOldDate
              : startDateForCompare,
            endOldDate: context.state.filter?.endOldDate
              ? context.state.filter?.endOldDate
              : endDateForCompare,
            campagneId: payload?.dashbordData
              ? payload?.campaign.campagneId
              : payload?.campagneId,
            gridId: payload?.dashbordData
              ? payload?.campaign.gridSelected?.id
              : payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get(
            '/agent/statAgent/' + context.state.model.topFlop + '/false',
            config,
          )
          .then(async (res) => {
            await context.commit('SET_STAT_COLLECTION', res.data.data.data);

            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            await context.commit('SET_IS_FETCHING', false);
            resolve(res.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) context.commit('SET_IS_FETCHING', false);

            //context.commit('SET_IS_FETCHING', false);
            //reject(err);
          });
      });
    },
    async getTopFlopAgentsData(context, payload) {
      context.commit('SET_IS_FETCHING', true);
      if (context.state.cancel) {
        context.state.cancel();
      }
      const cancelSource = CancelToken.source();
      context.commit('SET_CANCEL', cancelSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDateForCompare = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };

        const startDateForCompare = formatDateForCompare(oneWeekAgo);
        const endDateForCompare = formatDateForCompare(today);

        let page = context.state.pagination.current_page;
        if (context.state.pagination.last_page > page && page==0) {
          page = context.state.pagination.current_page + 1;
        }
        const config = {
          per_page: context.state.pagination.per_page,
          params: {
            //  per_page: context.state.pagination.per_page,
            ...context.state.filter,
            page: page,
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDateForCompare,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDateForCompare,
            startOldDate: context.state.filter?.startOldDate
              ? context.state.filter?.startOldDate
              : startDateForCompare,
            endOldDate: context.state.filter?.endOldDate
              ? context.state.filter?.endOldDate
              : endDateForCompare,
            campagneId: payload.dashbordData
              ? payload?.campaign.campagneId
              : payload?.campagneId,
            gridId: payload?.dashbordData
              ? payload?.campaign.gridSelected?.id
              : payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get(
            '/agent/topFlop/' + context.state.model.topFlop + '/false',
            config,
          )
          .then(async (res) => {
            if (payload.dashbordData) {
              let mergedArray = [
                ...payload.dashbordData,
                ...res.data.data.data,
              ];
              await context.commit('SET_TOP_FLOP_COLLECTION', mergedArray);
            } else {
              await context.commit(
                'SET_TOP_FLOP_COLLECTION',
                res.data.data.data,
              );
            }

            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            await context.commit('SET_IS_FETCHING', false);
            resolve(res.data.data);
          })
          .catch((err) => {
            //context.commit('SET_IS_FETCHING', false);
            //reject(err);
          });
      });
    },
    setTopFlop(context, payload) {
      context.commit('SET_TOP_FLOP', payload);
    },
  },
  getters: {
    getIsFetching: (state) => state.isFetchingData,
    getIsFetchingDetails: (state) => state.isFetchingDetails,
    getModel: (state) => state.model,
    getPagination: (state) => state.pagination,
    getDefaultPagination: (state) => {
      return defaultState.agent.pagination.per_page;
    },
    getCollection: (state) => state.collection,
    getFilter: (state) => state.filter,
    getErrors: (state) => state.errors,
    getTopFlopCollection: (state) => state.topFlopCollection,
    getStatCollection: (state) => state.statCollection,
    getMoreDetailsCollection: (state) => state.moreDetailsCollection,
    getFiltredCollection: (state) => state.filtredCollection,

  },
};
