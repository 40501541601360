import { axios } from '../services/axios.js';

export default {
  namespaced: true,
  state: {
    defaultModel: {
      name: '',
      campagne: '',
      statuts: 0,
      updatedAt: '',
      deletedAt: '',
      campagneId: null,
      categories: [],
      selectedCategorie: null,
      draft: '',
    },
    model: {
      name: '',
      campagne: '',
      statuts: 0,
      updatedAt: '',
      deletedAt: '',
      campagneId: null,
      categories: [],
      selectedCategorie: null,
      draft: '',
    },
    versionsResult: [],
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 5,
      page: 1,
    },
    versioningPagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 5,
      page: 1,
    },
    filter: {
      keyword: '',
      startDate: null,
      endDate: null,
      campagne: [],
      withPagination: 0,
      trashed: false,
      sortOrder: null,
      sortKey: null,
    
    },
    collection: [],
    versionningCollection: [],
    errors: [],
    isFetchingData: false,
    isFetchingVersioningData: false,
  },
  mutations: {
    ADD_VERSIONING(state, payload) {
      state.versionningCollection = [payload, ...state.versionningCollection];
    },
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    DELETE(state, payload) {
      state.collection = state.collection.filter(
        (gridItem) => gridItem.id !== payload,
      );
    },
    UPDATE(state, payload) {
      let index = state.collection.findIndex((el) => el.id === payload.id);
      state.collection.splice(index, 1, payload);
    },
    SET_MODEL(state, payload) {
      
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        campagne: '',
        statuts: '',
        updateAt: '',
        deletedAt: '',
        categories: [],
        selectedCategorie: null,
        draft: '',
        campagneId: null,
      };
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    ADD_CATEGORY_TO_GRID(state, payload) {
      state.model.categories = [payload, ...state.model.categories];
    },
    UPDATE_CATEGORY_IN_GRID(state, payload) {
      let index = state.model.categories.findIndex(
        (el) => el.id === payload.id,
      );
      state.model.categories.splice(index, 1, payload);
    },
    DELETE_CATEGORY_FROM_GRID(state, payload) {
      state.model.categories = state.model.categories.filter(
        (elm) => elm?.id !== payload,
      );
    },
    SET_IS_FETCHING(state, payload) {
      state.isFetchingData = payload;
    },
    SET_VERSIONING_PAGINATION(state, payload) {
      state.versioningPagination = payload;
    },
    SET_VERSION_RESULT(state, payload) {
      state.versionsResult = payload;
    },
    SET_IS_FETCHING_VERSIONING(state, payload) {
      state.isFetchingVersioningData = payload;
    },
    RESET_VERSION_RESULT(state) {
    state.versionsResult = [];
  },
    RESET_VERSIONING_PAGINATION(state) {
      state.versioningPagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 5,
        page: 1,
      };
    },
    SET_CAMPAGNE_ID(state, payload) {
      state.model.campagneId = payload;
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 5,
        page: 1,
      };
    },
    RESET_STATE(state, intialState) {
      Object.keys(intialState).forEach((key) => {
        state[key] = intialState[key];
      });
      
    },
    RESET_FILTER(state,payload) {
      state.filter = payload;
    },
    DELETE_ITEM_FROM_GRID(state, payload) {
      state.model.categories.forEach((category, categoryIndex) => {
        const itemIndex = category.items.findIndex(item => item.id === payload);
        if (itemIndex !== -1) {
          state.model.categories[categoryIndex].items.splice(itemIndex, 1);
        }
      });
    },
  },

  actions: {
    deleteItemFromGrid(context, payload) {
      context.commit('DELETE_ITEM_FROM_GRID', payload);
    },
    setGridModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetGridModel(context) {
      context.commit('RESET_MODEL');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    setPaginationVersioning(context, payload) {
      context.commit('SET_VERSIONING_PAGINATION', payload);
    },
    resetGridVersioning(context) {
      context.commit('RESET_VERSION_RESULT');
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    resetState(context) {
      context.commit('RESET_STATE', context.state.defaultModel);
      context.commit('RESET_PAGINATION');
      context.commit('RESET_FILTER',{
        keyword: '',
        startDate: null,
        endDate: null,
        campagne: [],
        withPagination: 1,
        trashed: false,
        sortOrder: null,
        sortKey: null,
      
      });
    },
    async getData(context) {
      
      context.commit('SET_IS_FETCHING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/grid', config)
          .then(async (res) => {
            context.commit('SET_IS_FETCHING', false);
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING', false);
            //reject(err);
          });
      });
    },
    async deleteGrid(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page ? context.state.pagination.per_page : 5,
            page: context.state.pagination.current_page,
            keyword: context.state.filter.keyword,
            withPagination: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          data: { ids: payload },
        };
        axios
          .delete('/grid', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            resolve(res.data.data);
          })
          .catch((err) => {
            //reject(err);
          });
      });
    },
    async storeGrid(context) {
      context.commit('SET_ERRORS', []);
      let data = context.state.model;
      return await new Promise((resolve) => {
        const config = {
          params: {
            per_page: context.state?.pagination?.per_page ? context.state.pagination.per_page : 5,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/grid/store', data, config)
          .then(async (res) => {
           
            context.commit('SET_MODEL',data)
            context.commit('SET_COLLECTION', res.data.data.data);
            context.commit('SET_PAGINATION', res.data.pagination);
            context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.data.status_code === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },
    async updateGrid(context) {
      context.commit('SET_ERRORS', null);
      let data = context.state.model;
      return await new Promise((resolve) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
           
        axios
          .put('/grid/update/' + data.id, data, config)
          .then(async (res) => {
            if (res.status === 206) {
              context.commit('SET_MODEL',data)
              context.commit('UPDATE', res.data.data.data);
              context.commit('SET_PAGINATION', res.data.pagination);
              //context.commit('RESET_MODEL');
            } else {
              context.commit('SET_MODEL',data)
              context.commit('UPDATE', res.data.data);
              context.commit('SET_PAGINATION', res.data.pagination);
            
              context.commit('RESET_MODEL');
            }
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
            //reject(err);
          });
      });
    },
    saveGidToStore(context, payload) {
      context.commit('ADD_CATEGORY_TO_GRID', payload);
    },
    updateCategoryInGrid(context, payload) {
      context.commit('UPDATE_CATEGORY_IN_GRID', payload);
    },
    deleteCategoryFromGrid(context, payload) {
      context.commit('DELETE_CATEGORY_FROM_GRID', payload);
    },
    async duplicateGrid(context, payload) {
      return await new Promise((resolve) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/grid/duplicate/' + payload, null, config)
          .then(async (res) => {
            context.commit('SET_COLLECTION', res.data.data.data);
            context.commit('SET_PAGINATION', res.data.pagination);
            context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.data.status_code === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },
    async getGridVersioning(context, payload) {
      context.commit('SET_IS_FETCHING_VERSIONING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.versioningPagination.per_page,
            page: context.state.versioningPagination.current_page,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/grid/version/' + payload, config)
          .then(async (res) => {
            context.commit('SET_IS_FETCHING_VERSIONING', false);
            if (res.data.pagination) {
              await context.commit(
                'SET_VERSIONING_PAGINATION',
                res.data.pagination,
              );
              await context.commit('SET_VERSION_RESULT', res.data.data.data);
            }
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING_VERSIONING', false);
            //reject(err);
          });
      });
    },
    async saveGridVersion(context, payload) {
      context.commit('SET_IS_FETCHING_VERSIONING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .post('/grid/version', payload, config)
          .then(async (res) => {
            context.commit('SET_IS_FETCHING_VERSIONING', false);
            context.commit('SET_VERSIONING_PAGINATION', res.data.pagination);
            context.commit('RESET_MODEL');
            resolve(res.data.data);
            context.commit('RESET_VERSIONING_PAGINATION');
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING_VERSIONING', false);
            //reject(err);
          });
      });
    },

    async uploadGrids(context,payload) {
      context.commit('SET_ERRORS', []);
      let data = context.state.model;
      return await new Promise((resolve) => {
        const file = payload;
        this.importError = false;
        this.importErrorMessage = "";
        this.uploadingSuccess = false;
        this.uploading = true;
        const formData = new FormData();
        formData.append("file",file);
        const config = {
          params: {
            per_page: context.state?.pagination?.per_page ? context.state.pagination.per_page : 5,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/grid/upload',formData, config)
          .then(async (res) => {
            context.commit('SET_COLLECTION',res.data.data.data);
            context.commit('SET_PAGINATION', res.data.pagination);
            context.commit('RESET_MODEL');
           
            resolve(true);
          })
          .catch((err) => {
            if (err.response.data.status_code === 422) {
              resolve(false)
              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },
    async lockGrid(context,payload) {
      let gridId = payload;
      return await new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/grid/lock/'+ gridId,null, config)
          .then(async (res) => {
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            if (err.response.data.status_code === 422) {

              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },
    async unlockGrid(context,payload) {
      let gridId = payload;
      return await new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/grid/unlock/'+ gridId,null, config)
          .then(async (res) => {
           
            resolve(true);
          })
          .catch((err) => {
            if (err.response.data.status_code === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },
    async exportGrids(context,payload) {
      let selectedRows = payload
      return await new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/grid/export',{ selectedRows },config)
          .then(async (res) => {
            resolve(res.data);
          })
          .catch((err) => {
            if (err.response && err.response.data.status_code === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },
  },
  getters: {
    getGridModel: (state) => state.model,
    getGridCollection: (state) => state.collection,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getFilter: (state) => state.filter,
    getIsFetching: (state) => state.isFetchingData,
    getPaginationVersioning: (state) => state.versioningPagination,
    getVersion: (state) => state.versionsResult,
    getIsFetchingVersioning: (state) => state.isFetchingVersioningData,
  },
};
