<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  created() {
    this.Whoami()
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    ...mapActions("user", ["Whoami"]),
    ...mapActions("notifications", [
      "fetchNotifications",
      "pushNotification",
      "getNotificationById",
      "markAsread",
    ]),
    ...mapActions("record", ["recordTranscriptionFinished"]),
  },
  mounted() {
    this.fetchNotifications();
    window.Echo?.private(`App.Models.User.1`).notification(async (notification) => {
      await this.getNotificationById(notification.id).then((res) => {
        this.pushNotification(res);
      });
      await this.recordTranscriptionFinished(notification.record).then((result) => {
        this.markAsread(notification.id);
      });
    });
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    user() {
      return this.getuser;
    },
  },
};
</script>
