import defaultState from '../defaultState/index.js';
import { axios, CancelToken, isCancel } from '../services/axios.js';
export default {
  namespaced: true,
  state: {...defaultState.calibrage},
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    // DELETE(state, payload) {
    //   state.collection = state.collection.filter(
    //     (record) => record.id != payload.id,
    //   );
    // },
    UPDATE(state, payload) {
      let index = state.collection.findIndex((x) => x.id === payload.id);
      state.collection.splice(index, 1, payload);
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        title: null,
        status: null,
        campagneId: null,
        callCenterId: null,
        recordId: null,
        startDate: null,
        memberCalibrations: [],
        recordCalibrations: [],
        calibrationEmails: [
          {
            email: null,
            firstName: null,
            lastName: null,
          },
        ],
      };
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_MEMBERS(state, payload) {
      state.model.memberCalibrations = payload;
    },
    SET_IS_FETCHING(state, payload) {
      state.isFetchingData = payload;
    },
    SET_CANCEL(state, cancel) {
      state.cancel = cancel;
    },
    RESET_STATE(state, intialState) {
      Object.keys(intialState).forEach(key => {
        state[key] = intialState[key]
      })
    },
    REMOVE_SELECTED(state, { itemId }) {
      const indexToRemove = state.model.recordCalibrations.findIndex(record => record.id === itemId);

      if (indexToRemove !== -1) {
        // Utiliser splice pour retirer l'élément du tableau
        state.model.recordCalibrations.splice(indexToRemove, 1);
      }
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
      };
    },
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    resetState(context) {
      context.commit('RESET_STATE',defaultState.calibrage);
    },
    setCollection(context, payload) {
      context.commit('SET_COLLECTION', payload);
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    async getData(context) {
      context.commit('SET_IS_FETCHING', true);
      return await new Promise((resolve, reject) => {
        if (context.state.cancel) {
          context.state.cancel();
        }

        const cancelSource = CancelToken.source();

        context.commit('SET_CANCEL', cancelSource.cancel);

        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            withPagination: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/calibration', config)
          .then(async (res) => {
            context.commit('SET_IS_FETCHING', false);
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }else{   
              await context.commit('RESET_PAGINATION');
            }
            resolve(res.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) {
              context.commit('SET_IS_FETCHING', false);
              reject(err);
            }
          });
      });
    },
    async createCalibration(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .post('/calibration', context.state.model, config)
          .then((res) => {
            if (
              context.state.collection.length >=
              context.state.pagination.per_page
            ) {
              context.state.collection.splice(-1, 1),
                context.commit('ADD', res.data.data);
            } else {
              context.commit('ADD', res.data.data);
            }

            context.commit('SET_PAGINATION', res.data.pagination);

            context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            if (err.response.status === 404) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
            reject(false);
          });
      });
    },
    async updateCalibration(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .put(
            '/calibration/' + context.state.model.id,
            context.state.model,
            config,
          )
          .then(async (res) => {
            await context.commit('UPDATE', res.data.data.data);
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(true);
          });
      });
    },
    async deleteCalibration(context, payload) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            keyword: context.state.filter.keyword,
            withPagination: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          data: { ids: payload },
        };
        axios
          .delete('/delete/calibration', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            //reject(err);
          });
      });
    },
    async cancelCalibration(context, payload) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .put(
            '/cancelCalibration/' + payload,
            null,
            config,
          )
          .then(async (res) => {
            await context.commit('UPDATE', res.data.data.data);
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(true);
          });
      });
    },
    setMembers(context, payload) {
      context.commit('SET_MEMBERS', payload);
    },
  },
  getters: {
    getIsFetching: (state) => state.isFetchingData,
    getModel: (state) => state.model,
    getPagination: (state) => state.pagination,
    getCollection: (state) => state.collection,
    getFilter: (state) => state.filter,
    getErrors: (state) => state.errors,
  },
};
