var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_nav"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('routes.names.' + _vm.getRouteName)))])]),_c('div',{staticClass:"nav"},[_c('ul',{staticClass:"list-menu"},[(!this.getIsGuest)?_c('li',{staticClass:"list-item none"},[_c('div',{staticClass:"dark"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.theme),expression:"theme"}],attrs:{"type":"checkbox","id":"darkmode-toggle"},domProps:{"checked":Array.isArray(_vm.theme)?_vm._i(_vm.theme,null)>-1:(_vm.theme)},on:{"change":[function($event){var $$a=_vm.theme,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.theme=$$a.concat([$$v]))}else{$$i>-1&&(_vm.theme=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.theme=$$c}},_vm.handleToggleDarkMode]}}),_vm._m(0)])]):_vm._e(),_vm._m(1),_c('li',{staticClass:"list-item none",on:{"click":function($event){return _vm.toggleNotificationsDropdown()}}},[_c('div',{staticClass:"notifications-icon"},[_c('i',{staticClass:"icon-notif",class:{ hasNotifications: _vm.hasNotifications }})])]),_c('DropDownMenu',{attrs:{"disabled":false,"items":[
          {
            label: _vm.$t('common.MonProfil'),
            onClick: _vm.toggleDrawer,
            iconClass: 'icon-profile mr-1',
          },
          {
            label: _vm.$t('common.Settings'),
            onClick: () => {
              _vm.navigateTo('/settings');
            },
            iconClass: ' icon-settings mr-1',
            visible: _vm.$globals.hasAuthorities('settings', 'navigate', _vm.role),
          },
          {
            label: _vm.userActionLabel,
            onClick: _vm.handleLogout,
            iconClass: ' icon-logout mr-1',
          },
        ],"icon":"icon-down","iconLeft":"icon-profile","text":_vm.$t(
            `${this.$globals.capitalizeFirstLetter(this.getUser?.lastName)} ${this.$globals.capitalizeFirstLetter(this.getUser?.firstName)}`,
          ),"btnId":'btn_dropdownProfile',"dropdownId":'dropdownMenuProfile',"btnclass":'btn-primary'}})],1),_c('DrawerProfil',{attrs:{"isOpened2":_vm.showDrawer},on:{"close":function($event){_vm.showDrawer = false}}}),_c('NotificationsDropdown')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"darkmode-toggle"}},[_c('i',{staticClass:"icon-sun sun"}),_c('i',{staticClass:"icon-moon moon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item none"},[_c('i',{staticClass:"icon-message"})])
}]

export { render, staticRenderFns }