<template>
  <div>
    <aside class="sidebar">
      <div class="logo">
        <img src="../assets/images/logo/logo_dashboard.svg" alt="Logo" />
      </div>
      <div class="">
        <ul class="menu_principal scroll">
          <li :class="{ 'router-link-active': path === '/dashboard' }">
            <router-link to="/dashboard">
              <i class="icon-dashboard mr-2"></i>
              Dashboard
            </router-link>
          </li>
          <ul>
            <li
              data-toggle="teams__"
              class="nav_accordion"
              @click="close_accordion"
              :class="{ 'router-link-active': areStringsInRoute }"
            >
              <p class="flex align-items">
                <i class="icon-teams mr-2"></i>
                Administrations
                <i class="icon-down ml-2"></i>
              </p>
            </li>
            <ul
              id="teams__"
              class="list_menu_accordion"
              :class="{ 'block-open': areStringsInRoute }"
            >

              <li v-for="(link, index) in visibleLinks" :key="index" :class="{ 'bold-font': path === link.to }">
                <router-link :to="link.to">{{ link.text }}</router-link>
              </li>

            </ul>
          </ul>

          <li v-for="(link, index) in visibleLinksSecondary" :key="index" :class="{ 'router-link-active': path === link.to }">
            <router-link :to="link.to">
              <i :class="link.iconClass"></i>
              {{ link.text }}
            </router-link>
          </li>

        </ul>
        <ul class="menu_profil">
          <li>
            <a @click="toggleDrawer">
              <i class="icon-profil mr-2"></i>
              Profil
            </a>
          </li>

          <li
            :class="{ 'router-link-active': path === '/settings' }"
            v-if="
              $globals.hasAuthorities('settings', 'navigate', role) &&
              !isGuest &&
              !isLoading
            "
          >
            <router-link to="/settings">
              <i class="icon-settings mr-2"></i>
              Paramètres
            </router-link>
          </li>
          <li>
            <a @click="handleLogout">
              <i class="icon-logout mr-2"></i>
              Déconnexion
            </a>
          </li>
        </ul>
      </div>
    </aside>
    <DrawerProfil  :isOpened2="showDrawer" @close="showDrawer = false" />
  </div>
</template>

<script>
import DrawerProfil from '@/components/DrawerForm/Profil/DrawerProfil.vue';
import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    DrawerProfil,
  },
  data() {
    return {
      role: null,
      path: '',
      stringsToCheck: ['agents', 'teams', 'callcenters', 'qualifications', 'utilisateur'],
      isLoading: false,
      showDrawer: false,
      activecollapse: false,

      primaryLinks: [
        { to: '/teams', text: 'Équipes' },
        { to: '/agents', text: 'Agents' },
        { to: '/callcenters', text: 'Call-center', visible: () => this.$globals.hasAuthorities('callcenters', 'navigate', this.role) },
        { to: '/qualifications', text: 'Qualifications', visible: () => this.$globals.hasAuthorities('qualifications', 'navigate', this.role) },
        { to: '/utilisateurs', text: 'Utilisateurs', visible: () => this.$globals.hasAuthorities('utilisateurs', 'navigate', this.role) }
      ],
      secondaryLinks: [
        { to: '/campaigns', text: 'Campagnes', iconClass: 'icon-profil mr-2', visible: () => this.$globals.hasAuthorities('campaigns', 'navigate', this.role) },
        { to: '/records', text: 'Enregistrements', iconClass: 'icon-audio mr-2' },
        { to: '/calibrations', text: 'Calibrage', iconClass: 'icon-calibrage mr-2', visible: () => this.$globals.hasAuthorities('calibrations', 'navigate', this.role) },
        { to: '/training', text: 'Entrainement', iconClass: 'icon-calibrage mr-2', visible: () => this.$globals.hasAuthorities('training', 'navigate', this.role) },
        { to: '/grille-evaluations', text: 'Grilles d’évaluation', iconClass: 'icon-calibrage mr-2', visible: () => this.$globals.hasAuthorities('training', 'navigate', this.role) },
      ],
    };
  },

  computed: {
    ...mapGetters('detailsCalibrage', ['getIsGuest']),
    isGuest() {
      return this.getIsGuest;
    },
    areStringsInRoute() {
      // Check if all specified strings are present in the route path
      return this.stringsToCheck.some((str) => this.$route.path.includes(str));
    },
    visibleLinks() {
      return this.primaryLinks.filter(link => {
        if (typeof link.visible === 'function') {
          return link.visible();
        } else {
          return true;
        }
      });
    },
    visibleLinksSecondary() {
      return this.secondaryLinks.filter(link => {
        if (typeof link.visible === 'function') {
          return link.visible();
        } else {
          return true;
        }
      });
    }
  },

  methods: {
    ...mapActions('user', ['logout']),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'login' });
      });
    },

    // toggleadmin() {
    //   this.activecollapse = !this.activecollapse; // Toggle the value

    // },
    close_accordion() {
      document
        .querySelector('.nav_accordion')
        .nextSibling.classList.remove('block-open');
    },

    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    // async verifUserByCodeMeet(codeMeet) {
    //   const apiUrl = 'user/verifCodeMeet';
    //   const requestData = {
    //     codeMeet: codeMeet,
    //   };
    //   return await new Promise((resolve) => {
    //     let isGuest = false;
    //     let allMembersCallibration = [];
    //     axios
    //       .post(apiUrl, requestData)
    //       .then((response) => {
    //         isGuest = response.data.data.data.memberCallibration.is_quest;
    //         allMembersCallibration =
    //           response.data.data.data.allMembersCallibration;
    //         this.$store.dispatch('user/setGuest', isGuest);
    //         this.$store.dispatch(
    //           'calibrage/setMembers',
    //           allMembersCallibration,
    //         );
    //         resolve(isGuest);
    //       })
    //       .catch(() => {
    //         isGuest = false;
    //       });
    //   });
    // },
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);

    this.path = this.$route.path;
    const codeMeet = urlParams.get('calibration');
    if (codeMeet) {
      this.isLoading = true;
      // this.verifUserByCodeMeet(codeMeet).then((result) => {
      //   this.isGuest = result;
      //   this.isLoading = false;
      // });
    }
  },
  mounted() {
    this.role = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).role
      : 'visitor';
    $(document).ready(function () {
      $('.nav_accordion').click(function () {
        var target = $(this).data('toggle');

        // Remove the "active" class from all buttons
        $('.nav_accordion').removeClass('active');

        // Remove the "active" class from all toggle elements
        $('.list_menu_accordion').removeClass('active');

        // Close all other toggle elements
        $('.list_menu_accordion')
          .not('#' + target)
          .slideUp();

        // Toggle the target element
        $('#' + target).slideToggle();

        // Add the "active" class to the clicked button
        $(this).addClass('active');

        // Add the "active" class to the target element
        $('#' + target).toggleClass('active');
      });
    });
  },
  watch: {
    $route(to) {
      this.path = to.path;
    },
  },
};
</script>
