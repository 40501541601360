import { axios, CancelToken, isCancel } from '../services/axios.js';

export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: '',
      updated_at: '',
      toInsert: [],
      toUpdate: [],
      toDelete: [],
    },
    collection: [],
    collectionIdWithName: [],
    collectionForTeam: [],
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      page: 1,
    },
    filter: {
      keyword: '',
      withPagination: 1,
      startDate: null,
      endDate: null,
      sortOrder: null,
      sortKey: null,
      trashed: false,
    },
    errors: null,
    cancel: null,
    isFetchingDataLoading: false,
  },
  mutations: {
    ADD(state, payload) {
      // this mutation will add a new record to collection
      state.collection = [payload, ...state.collection];
      //state.collection = state.collection.unshift(payload);
      /* if (state.collection.length > state.pagiantion.per_page) {
        state.collection = state.collection.splice(
          state.collection.length - 1,
          1,
        );
      } */
    },
    DELETE(state, payload) {
      // this mutation will delete record by id from collection
      state.collection = state.collection.filter(
        (record) => record.id != payload.id,
      );
    },
    UPDATE() {},
    SET_MODEL(state, payload) {
      state.model = payload;
      state.model.toModifyAcounts = payload.acounts;
    },
    SET_IS_LOADING(state, payload) {
      state.isFetchingDataLoading = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        updated_at: '',
        toInsert: [],
        toUpdate: [],
        toDelete: [],
      };
    },
    RESET_ERRORS(state) {
      state.errors = null;
    },

    RESET_FILTER(state) {
      state.filter = {
        keyword: '',
        startDate: null,
        endDate: null,
        withPagination: 1,
        sortOrder: null,
        sortKey: null,
        trashed: false,
      };
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
      };
    },
    SET_COLLECTION(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.collection = payload;
    },
    SET_PAGINATION(state, payload) {
      // this mutation will change the pagination state values
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_CANCEL(state, cancel) {
      state.cancel = cancel;
    },
    SET_TO_UPDATE(state, payload) {
      state.model.toUpdate = payload;
    },
    SET_TO_DELETE(state, payload) {
      if (
        !Object.prototype.hasOwnProperty.call(state.model, 'toDelete') ||
        !Array.isArray(state.model.toDelete)
      ) {
        state.model.toDelete = [];
      }
      // Create a deep copy of state.model.acounts
      const collectionsCopy = JSON.parse(JSON.stringify(state.collection));

      // Now you can push the payload into the toDelete array
      state.model.toDelete.push(payload);

      // Additionally, you might want to update other properties in state.model if needed
      state.model.acounts = state.model.acounts.filter(
        (record) => record.id !== payload,
      );
      state.model.toModifyAcounts = state.model.toModifyAcounts.filter(
        (record) => record.id !== payload,
      );
      if (state.model.toUpdate) {
        state.model.toUpdate = state.model.toUpdate.filter(
          (record) => record.id !== payload,
        );
      }
      state.collection = collectionsCopy;
    },
    EDIT_ELEMENT(state, payload) {
      let collection = [];
      state.collection.forEach((element) => {
        if (element.id == payload.id) {
          collection.push(payload);
        } else {
          collection.push(element);
        }
      });
      state.collection = collection;
    },
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    resetPagination(context) {
      context.commit('RESET_PAGINATION');
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    resetFilter(context) {
      context.commit('RESET_FILTER');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    resetErrors(context) {
      context.commit('RESET_ERRORS');
    },
    setToUpdate(context, payload) {
      context.commit('SET_TO_UPDATE', payload);
    },
    setToDelete(context, payload) {
      context.commit('SET_TO_DELETE', payload);
    },
    async createQualification(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      context.commit('RESET_FILTER');
      context.commit('RESET_PAGINATION');

      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            ...(context.state.filter.withPagination === 1 &&
            context.state.pagination
              ? {
                  per_page: context.state.pagination.per_page,
                  page: context.state.pagination.current_page,
                }
              : {}),
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .post('/qualification', context.state.model, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            //reject(err);
          });
      });
    },
    async updateQualification(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .put(
            '/qualification/' + context.state.model.id,
            context.state.model,
            config,
          )
          .then(async (res) => {
            await context.commit('EDIT_ELEMENT', res.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            //reject(err);
          });
      });
    },
    async getData(context) {
      context.commit('SET_IS_LOADING', true);

      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            ...(context.state.filter.withPagination === 1 &&
            context.state.pagination
              ? {
                  per_page: context.state.pagination.per_page,
                  page: context.state.pagination.current_page,
                }
              : {}),
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/qualification', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (context.state.filter.withPagination === 1) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            } else {
              await context.commit('RESET_MODEL');
              await context.commit('RESET_FILTER');
            }
            context.commit('SET_IS_LOADING', false);

            resolve(res.data.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) context.commit('SET_IS_LOADING', false);

            //reject(err);
          });
      });
    },
    async deleteQualification(context, payload) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            keyword: context.state.filter.keyword,
            withPagination: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          data: { ids: payload },
        };
        axios
          .delete('/qualification/delete/' + context.state.model.id, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            //reject(err);
          });
      });
    },

    async getQualificationsStatistics(context, payload) {
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDateForCompare = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        const startDateForCompare = formatDateForCompare(oneWeekAgo);
        const endDateForCompare = formatDateForCompare(today);
        const config = {
          params: {
            ...context.state.filter,
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDateForCompare,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDateForCompare,
            startOldDate: context.state.filter?.startOldDate
              ? context.state.filter?.startOldDate
              : startDateForCompare,
            endOldDate: context.state.filter?.endOldDate
              ? context.state.filter?.endOldDate
              : endDateForCompare,
            campagneId: payload?.campagneId,
            gridId: payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/qualifications/statistic', config)
          .then(async (res) => {
            // context.commit('isFetchingDataLoading', false);
            await context.commit('SET_COLLECTION', res.data.data.data);
            resolve(res.data.data.data);
          })
          .catch(() => {
            // context.commit('isFetchingDataLoading', false);
          });
      });
    },
  },
  getters: {
    getState: (state) => state,
    getModel: (state) => state.model,
    getPagination: (state) => state.pagination,
    getCollection: (state) => state.collection,
    getIdWithName: (state) => state.collectionIdWithName,
    getCollectionTeam: (state) => state.collectionForTeam,
    getFilter: (state) => state.filter,
    getErrors: (state) => state.errors,
  },
};
