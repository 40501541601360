import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './scss/main.scss';
import i18n from './i18n';
import globals from '../globals.js';
import VueEllipseProgress from 'vue-ellipse-progress';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { axios } from './store/services/axios';

Vue.config.productionTip = false;
Vue.prototype.$globals = globals;
Vue.use(VueEllipseProgress);

// window.Pusher = Pusher;
// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_PUSHER_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
//   wsPort: 6001,
//   disableStats: false,
//   forceTLS: false,
//   cluster: "mt1",
//   authorizer: (channel, options) => {
//     return {
//       authorize: (socketId, callback) => {
//         axios
//           .post(
//             "/api/broadcasting/auth",
//             {
//               socket_id: socketId,
//               channel_name: `private-App.Models.User.${JSON.parse(localStorage.getItem('user'))?.id}`,
//             },
//             {
//               headers: {
//                 Authorization: `Bearer ${localStorage.getItem("auth")}`,
//               },
//             }
//           )
//           .then((response) => {
//             callback(false, response.data);
//           })
//           .catch((error) => {
//             callback(true, error);
//           });
//       },
//     };
//   },
// });

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
