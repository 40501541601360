<template>
  <div class="content_nav">
    <div class="title">
      <h2>{{ $t('routes.names.' + getRouteName) }}</h2>
    </div>
    <div class="nav">
      <ul class="list-menu">
        <li v-if="!this.getIsGuest" class="list-item none">
          <div class="dark">
            <input
              type="checkbox"
              v-model="theme"
              id="darkmode-toggle"
              @change="handleToggleDarkMode"
            />
            <label for="darkmode-toggle">
              <i class="icon-sun sun"></i>
              <i class="icon-moon moon"></i>
            </label>
          </div>
        </li>

        <li class="list-item none">
          <i class="icon-message"></i>
        </li>
        <li @click="toggleNotificationsDropdown()" class="list-item none">
          <div class="notifications-icon">
            <i
              :class="{ hasNotifications: hasNotifications }"
              class="icon-notif"
            ></i>
          </div>
        </li>
        <!-- <li v-if="!this.getIsGuest" class="list-item flex align-items profil">
          <i class="icon-profil mr-2"></i>
          {{ this.$globals.capitalizeFirstLetter(this.getUser?.lastName) }} {{ this.$globals.capitalizeFirstLetter(this.getUser?.firstName) }}
          <i class="icon-down ml-2"></i>
        </li> -->
        <DropDownMenu
          :disabled="false"
          :items="[
            {
              label: $t('common.MonProfil'),
              onClick: toggleDrawer,
              iconClass: 'icon-profile mr-1',
            },
            {
              label: $t('common.Settings'),
              onClick: () => {
                navigateTo('/settings');
              },
              iconClass: ' icon-settings mr-1',
              visible: $globals.hasAuthorities('settings', 'navigate', role),
            },
            {
              label: userActionLabel,
              onClick: handleLogout,
              iconClass: ' icon-logout mr-1',
            },
          ]"
          icon="icon-down"
          iconLeft="icon-profile"
          :text="
            $t(
              `${this.$globals.capitalizeFirstLetter(this.getUser?.lastName)} ${this.$globals.capitalizeFirstLetter(this.getUser?.firstName)}`,
            )
          "
          :btnId="'btn_dropdownProfile'"
          :dropdownId="'dropdownMenuProfile'"
          :btnclass="'btn-primary'"
        />
      </ul>
      <DrawerProfil :isOpened2="showDrawer" @close="showDrawer = false" />
      <NotificationsDropdown />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NotificationsDropdown from '@/components/Notifications/Dropdown.vue';
import DropDownMenu from '@/components/DropDownMenu/DropDownMenu.vue';
import DrawerProfil from '@/components/DrawerForm/Profil/DrawerProfil.vue';
export default {
  components: {
    NotificationsDropdown,
    DropDownMenu,
    DrawerProfil,
  },
  data() {
    return {
      role: null,
      // darkMode: this.theme,
      isGuest: false,
      showDrawer: false,
      //path: '',
    };
  },
  methods: {
    ...mapActions('user', ['setUser', 'updateUser', 'logInAsAnotherUser']),
    handleLogout() {
      if (localStorage.getItem('IdOldUser')) {
        this.logInAsAnotherUser();
      } else {
        this.logout().then(() => {
          this.$router.push({ name: 'login' });
        });
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    toggleDarkMode() {
      if (this.theme) {
        // If dark mode is enabled, add the 'dark-mode' class to the body
        document.body.classList.add('dark-mode');
      } else {
        // If dark mode is disabled, remove the 'dark-mode' class from the body
        document.body.classList.remove('dark-mode');
      }
    },

    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },

    async handleToggleDarkMode() {
      if (this.theme) {
        this.updateUser({ theme: 'light' });
        this.setUser({
          ...this.getUser,
          ['preferedTheme']: 'light',
        });
      } else {
        this.updateUser({ theme: 'dark' });
        this.setUser({
          ...this.getUser,
          ['preferedTheme']: 'dark',
        });
      }
    },
    switchLanguage(lang) {
      this.updateUser({ language: 'lang' });
      this.$i18n.locale = lang; // Change the language on button click
    },
    toggleNotificationsDropdown() {
      const dropdown = document.getElementById('notificationDropdown');
      dropdown.style.display =
        dropdown.style.display === 'none' ? 'block' : 'none';
    },
  },
  created() {},
  mounted() {
    // const btn_langue = document.getElementById('btn-langue');
    this.role = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).role
      : 'visitor';
    this.toggleDarkMode();
  },
  beforeUpdate() {},
  updated() {},
  computed: {
    ...mapGetters('user', ['getUser']),
    ...mapGetters('detailsCalibrage', ['getIsGuest']),
    ...mapGetters('notifications', ['getNotifications']),
    ...mapGetters('app', ['getRouteName']),
    theme() {
      return this.getUser?.preferedTheme === 'dark';
    },
    hasNotifications() {
      return this.getNotifications.some((x) => x.read_at == null);
    },
    userActionLabel() {
      const IdOldUser = localStorage.getItem('IdOldUser');
      if (IdOldUser) {
        try {
          const parsedIdOldUser = JSON.parse(IdOldUser);
          return parsedIdOldUser
            ? this.$t('common.BackToPreviousSession')
            : this.$t('common.Logout');
        } catch (e) {
          return this.$t('common.Logout');
        }
      } else {
        return this.$t('common.Logout');
      }
    },
  },
  watch: {
    theme() {
      this.toggleDarkMode();
    },
    $route(to) {
      this.path = to.path;
    },
  },
};
</script>
