var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dropdown-content",attrs:{"id":"notificationDropdown"}},[_c('div',{staticClass:"notifications-header"},[_c('span',{staticClass:"header-title"},[_vm._v("Notifications")]),_c('span',{staticClass:"all-read",on:{"click":_vm.readAll}},[_vm._v("Tout marquer comme lu")])]),_c('div',{staticClass:"notifications-body scroll"},_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id,staticClass:"notification-item",class:{ unreaded: notification.read_at === null }},[(
            notification.type ===
            'App\\Notifications\\RecordTranscriptionAccomplishedNotification'
          )?_c('TranscriptionNotification',{attrs:{"notification":notification}}):_vm._e(),(
            notification.type ===
            'App\\Notifications\\TrainingRecordTranscriptionAccomplishedNotification'
          )?_c('TrainingTranscriptionNotification',{attrs:{"notification":notification}}):_vm._e()],1)}),0),(_vm.isFetching)?_c('div',{staticClass:"text-center"},[_c('Loader')],1):_vm._e(),(!_vm.isFetching && _vm.pagination.current_page < _vm.pagination.last_page)?_c('div',{staticClass:"notifications-footer"},[_c('span',{staticClass:"show-more",on:{"click":_vm.nextPage}},[_vm._v("Afficher plus")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }