<template>
  <div>
    <div class="dropdown-content" id="notificationDropdown">
      <div class="notifications-header">
        <span class="header-title">Notifications</span>
        <span class="all-read" @click="readAll">Tout marquer comme lu</span>
      </div>
      <div class="notifications-body scroll">
        <div
          v-for="notification of notifications"
          :key="notification.id"
          class="notification-item"
          :class="{ unreaded: notification.read_at === null }"
        >
          <TranscriptionNotification
            v-if="
              notification.type ===
              'App\\Notifications\\RecordTranscriptionAccomplishedNotification'
            "
            :notification="notification"
          />
          <TrainingTranscriptionNotification
            v-if="
              notification.type ===
              'App\\Notifications\\TrainingRecordTranscriptionAccomplishedNotification'
            "
            :notification="notification"
          />
        </div>
      </div>
      <div class="text-center" v-if="isFetching">
        <Loader />
      </div>
      <div
        v-if="!isFetching && pagination.current_page < pagination.last_page"
        class="notifications-footer"
      >
        <span @click="nextPage" class="show-more">Afficher plus</span>
        <!--  <span v-if="pagination.current_page == pagination.last_page">
          Pas d'autre notification
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "../AnimationScribb/Loader.vue";
import TranscriptionNotification from "./TranscriptionNotification.vue";
import TrainingTranscriptionNotification from "./TrainingTranscriptionNotfication.vue";

export default {
  components: {
    Loader,
    TranscriptionNotification,
    TrainingTranscriptionNotification
  },
  mounted() {
    /* window.onclick = function (event) {
      var dropdown = document.getElementById("notificationDropdown");
      if (event.target !== dropdown && !dropdown.contains(event.target)) {
        dropdown.style.display = "none";
      }
    }; */
  },
  methods: {
    ...mapActions("notifications", [
      "setPagination",
      "fetchNotifications",
      "readAllNotifications",
    ]),
    nextPage() {
      this.setPagination({
        ...this.getPagination,
        ["current_page"]: this.getPagination.current_page + 1,
      });
      this.fetchNotifications();
    },
    readAll() {
      this.readAllNotifications();
    },
  },
  computed: {
    ...mapGetters("notifications", [
      "getNotifications",
      "getPagination",
      "getIsFetching",
    ]),
    notifications() {
      return this.getNotifications;
    },
    pagination() {
      return this.getPagination;
    },
    isFetching() {
      return this.getIsFetching;
    },
  },
};
</script>

