import { axios } from '../services/axios.js';

export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: '',
      items: [],
      gridId: null,
    },
    collection: [],
    errors: null,
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        items: [],
        gridId: null,
      };
      state.collection = [];
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    ADD_ITEM_TO_CATEGORY(state, payload) {
      state.model.items = [payload, ...state.model.items];
    },
    UPDATE_ITEM_IN_CATEGORY(state, payload) {
      let index = state.model.items.findIndex((el) => el.id === payload.id);
      state.model.items.splice(index, 1, payload);
    },
  
    DELETE_ITEM_FROM_CATEGORY(state, payload) {
     

      let result = state.model.items.filter(
        (elm) => elm?.id !== payload,
      );
 
      state.model= {...state.model,
                      items:result}
     
    },
    // UPDTAE_ITEM_ORDER(state) {
    //   state.items = state.items.map((item) => {
    //     item.find((updateditem) => updateditem.itemId === item.id);
    //   });
    // },
  },

  actions: {
    setCategoryModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetCategoryModel(context) {
      context.commit('RESET_MODEL');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    addCategory(context, payload) {
      context.commit('ADD', payload);
    },
    addItemToCategory(context, payload) {
      context.commit('ADD_ITEM_TO_CATEGORY', payload);
    },
    updateItemInCategory(context, payload) {
      context.commit('UPDATE_ITEM_IN_CATEGORY', payload);
    },
    deleteItemFromCategory(context, payload) {
      context.commit('DELETE_ITEM_FROM_CATEGORY', payload);
    },
    // async updateItemOrder(context, payload) {
    //   let orders = payload.map((item, index) => ({
    //     order: index,
    //     itemId: item.id,
    //   }));
    //   return await new Promise(() => {
    //     const config = {
    //       params: {
    //         per_page: context.state.pagination.per_page,
    //         page: 1,
    //       },
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem('auth')}`,
    //         Accept: 'application/json',
    //       },
    //     };
    //     axios
    //       .put('item/updateOrder', { order: orders }, config)
    //       .then(async (response) => {
    //         response.data.data.data;
    //         context.commit('UPDTAE_ITEM_ORDER');
    //       })
    //       .catch((err) => {
    //         return err;
    //       });
    //   });
    // },
  },
  getters: {
    getCategoryModel: (state) => state.model,
    getCategoryCollection: (state) => state.collection,
    getErrors: (state) => state.errors,
  },
};
