import { axios } from '../services/axios.js';
export default {
    namespaced: true,
    state: {
        collection: [],
        pagination: {
            total: 0,
            current_page: 1,
            last_page: 1,
            per_page: 5,
        },
        isFetching: false
    },
    mutations: {
        PUSH(state, payload) {
            state.collection = [payload, ...state.collection]
        },
        SET_NOTIFICATIONS(state, payload) {
            state.collection = payload
        },
        SET_IS_FETCHING(state, payload) {
            state.isFetching = payload
        },
        SET_PAGINATION(state, payload) {
            state.pagination = payload;
        },
        UPDATE_NOTIFICATION(state, payload) {
            let notifications = []
            for (let item of state.collection) {
                if (item.id === payload.id) {
                    notifications.push(payload)
                } else {
                    notifications.push(item)
                }
            }
            state.collection = notifications
        }
    },
    actions: {
        async fetchNotifications(context, payload) {
            context.commit('SET_IS_FETCHING', true);
            return await new Promise((resolve, reject) => {
                const config = {
                    params: {
                        per_page: context.state.pagination.per_page,
                        page: context.state.pagination.current_page,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`,
                    },
                };
                axios
                    .get('/notifications', config)
                    .then(async (res) => {
                        context.commit('SET_IS_FETCHING', false);
                        await context.commit('SET_NOTIFICATIONS', [...context.state.collection, ...res.data.data.data]);
                        if (res.data.pagination) {
                            await context.commit('SET_PAGINATION', res.data.pagination);
                        }
                        resolve(res.data.data);
                    })
                    .catch((err) => {
                        context.commit('SET_IS_FETCHING', false);
                        // reject(err);
                    });
            });
        },
        pushNotification(context, payload) {
            context.commit('PUSH', payload)
        },
        async getNotificationById(context, payload) {
            return await new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`,
                    },
                };
                axios
                    .get('/notifications/' + payload, config)
                    .then(async (res) => {
                        resolve(res.data.data.data);
                    })
                    .catch(() => {
                        //reject(err);
                    });
            });
        },
        async markAsread(context, payload) {
            return await new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`,
                    },
                };
                axios
                    .put('/notifications/mark-as-read/' + payload, null, config)
                    .then(async (res) => {

                        context.commit('UPDATE_NOTIFICATION', res.data.data.data)
                        resolve(res.data.data.data);
                    })
                    .catch(() => {
                        // reject(err);
                    });
            });
        },
        setPagination(context, payload) {
            context.commit('SET_PAGINATION', payload);
        },
        async readAllNotifications(context, payload) {
            return await new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth')}`,
                    },
                };
                axios
                    .put('/notifications/mark-all-as-read', null, config)
                    .then(async (res) => {
                        const { data: notifications } = res.data.data
                        let currentNotifications = []
                        for (let notification of context.state.collection) {
                            currentNotifications.push({ ...notification, ['read_at']: notifications.find(x => x.id === notification.id).read_at })
                        }
                        context.commit('SET_NOTIFICATIONS', currentNotifications)
                    })
                    .catch(() => {
                        // reject(err);
                    });
            });
        }
    },
    getters: {
        getNotifications: (state) => state.collection,
        getPagination: (state) => state.pagination,
        getIsFetching: (state) => state.isFetching,
    },
};
