import Axios from 'axios';

const instance = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  responseType: 'json',
});
const axios = instance;
// axios.defaults.withCredentials = true;

export const CancelToken = Axios.CancelToken;
export const isCancel = Axios.isCancel;

export { axios };
