var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdwon_menu"},[_c('button',{class:{
    'dropdown-disabled': _vm.disabled && _vm.disabled == true,
    [_vm.btnclass]: true,
  },attrs:{"id":_vm.btnId}},[_c('i',{class:_vm.iconLeft}),_vm._v(" "+_vm._s(_vm.text)+" "),_c('i',{staticClass:"ml-3",class:_vm.icon})]),_c('div',{staticStyle:{"display":"none"},attrs:{"id":_vm.dropdownId}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(item.visible == true || item.visible == undefined)?_c('button',{class:{ 'item-disabled ': item.disabled && item.disabled == true },on:{"click":() => {
            item.onClick();
            _vm.toggleDropdown();
          }}},[_c('i',{class:item.iconClass}),_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }