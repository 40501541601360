import { axios, CancelToken, isCancel } from '../services/axios.js';
export default {
  namespaced: true,
  state: {
    defaultModel: {
      id: null,
      name: '',
      toModifyTeams: [],
      callCenterSelected: {},
      callCenterId: null,
      campagnesIds: '',
      campagnes: [],
      campagnesSelected: [],
      supervisers: [],
      supervisersSelected: [],
      agents: [],
      agentsSelected: [],
      agentsToRemove: [],
      agentsToChangeState: [],
      agentTransfertCollection: null,
      toInsert: [],
      toUpdate: [],
      toDelete: [],
      toEditStatus: [],
      topFlop: 'top',
      inDashboard: false,
    },
    model: {
      id: null,
      name: '',
      toModifyTeams: [],
      callCenterSelected: {},
      callCenterId: null,
      campagnesIds: '',
      campagnes: [],
      campagnesSelected: [],
      supervisers: [],
      supervisersSelected: [],
      agents: [],
      agentsSelected: [],
      agentsToRemove: [],
      agentsToChangeState: [],
      agentTransfertCollection: null,
      toInsert: [],
      toUpdate: [],
      toDelete: [],
      toEditStatus: [],
      topFlop: 'top',
      inDashboard: false,
    },
    AgentToRemove: {
      showAgentModal: false,
      sourceArray: '',
      targetArray: '',
      itemId: null,
    },
    collection: [],
    filtrdTeamCollection: [],
    collectionIdWithName: [],
    topFlopCollection: [],
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      page: 1,
    },
    filter: {
      keyword: '',
      startDate: null,
      endDate: null,
      onlyIdName: 0,
      callcenter: [],
      // withPagination: 1,
      startNewDate: null,
      endNewDate: null,
      startOldDate: null,
      endOldDate: null,
      withPagination: 0,
      campagneId: null,
      gridId: null,
      callCenters: [],
      agents: [],
      teams: [],
    },
    errors: null,
    cancel: null,
    isFetchingDataLoading: false,
    loadingButton: false,
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    DELETE(state, payload) {
      state.collection = state.collection.filter(
        (record) => record.id != payload.id,
      );
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isFetchingDataLoading = payload;
    },
    SET_IS_LOADING_BUTTON(state, payload) {
      state.loadingButton = payload;
    },
    SET_SHOW_AGENT_MODAL(state, payload) {
      state.AgentToRemove.showAgentModal = payload.show;
      state.AgentToRemove.itemId = payload.itemId || null;
      if (payload.itemId) {
        state.AgentToRemove.agent = state.model.agentsSelected.find(
          (item) => item.id === payload.itemId,
        );
      }
    },
    SET_MODEL_CALLCENTER(state, payload) {
      state.model.callCenterSelected = payload;
      state.model.callCenterId = payload.id;
    },
    ADD_MODEL_AGENT_STATE(state, payload) {
      state.model.agentsToChangeState.push(payload);
    },
    REMOVE_MODEL_AGENT_STATE(state, payload) {
      state.model.agentsToChangeState = state.model.agentsToChangeState.filter(
        (item) => item.id !== payload.id,
      );
    },
    SET_MODEL_EDIT(state, payload) {
      state.model.id = payload.id;
      state.model.name = payload.name;
      if (payload.callcenter)
        state.model.callCenterSelected = payload.callcenter;
      payload.agents.forEach((element) => {
        element.completeName = element.firstName + ' ' + element.lastName;
        state.model.agentsSelected.push(element);
      });
      payload.supervisers.forEach((element) => {
        element.completeName = element.firstName + ' ' + element.lastName;
        state.model.supervisersSelected.push(element);
      });
    },
    SET_MODEL_ETAT(state, payload) {
      const { item, dateDeleted } = payload;

      const index = state.model.toEditStatus.findIndex(
        (agent) => agent.id === item.id,
      );
      if (index === -1) {
        state.model.toEditStatus.push({
          ...item,
          deleted_at: dateDeleted,
        });
      } else {
        state.model.toEditStatus.splice(index, 1);
      }
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        toModifyTeams: [],
        callCenterSelected: {},
        callCenterId: null,
        campagnesIds: '',
        campagnes: [],
        campagnesSelected: [],
        supervisers: [],
        supervisersSelected: [],
        agents: [],
        agentsSelected: [],
        agentsToRemove: [],
        agentTransfertCollection: null,
        toInsert: [],
        toUpdate: [],
        toDelete: [],
        toEditStatus: [],
      };
      state.AgentToRemove = {
        showAgentModal: false,
        itemId: null,
      };
    },
    RESET_ERRORS(state) {
      state.errors = null;
    },
    SET_CANCEL(state, cancel) {
      state.cancel = cancel;
    },
    RESET_FILTER(state) {
      state.filter = {
        keyword: '',
        startDate: null,
        endDate: null,
        onlyIdName: 0,
        callcenter: [],
        withPagination: 1,
        startNewDate: null,
        endNewDate: null,
        startOldDate: null,
        endOldDate: null,
        campagneId: null,
        gridId: null,
      };
    },
    RESET_STATE(state, intialState) {
      Object.keys(intialState).forEach((key) => {
        state[key] = intialState[key];
      });
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
      };
    },
    SET_COLLECTION(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.collection = payload;
    },
    SET_FILTRED_TEAM_COLLECTION(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.filtrdTeamCollection = payload;
    },
    SET_CAMPAGNES_IDS(state) {
      const campagneIds = state.model.campagnesSelected.map(
        (element) => element.campagneId,
      );
      state.model.campagnesIds = campagneIds.join(',');
    },
    SET_COLLECTION_TEAMS(state, { arrayName, items }) {
      state.model[arrayName] = [];
      items.forEach((element) => {
        if (element.teamId == null) state.model[arrayName].push(element);
      });
    },
    SET_COLLECTION_ID_WITH_NAME(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.collectionIdWithName = payload;
    },
    SET_PAGINATION(state, payload) {
      // this mutation will change the pagination state values
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_ERRORS_BY_NAME(state, payload) {
      state.errors = { ...state.errors, ...payload };
    },
    FILTER_THE_AGENTS(state) {
      function filterSelected(allItems, selectedItems) {
        const selectedIds = selectedItems.map((item) => item.id);
        return allItems.filter((item) => !selectedIds.includes(item.id));
      }

      if (state.model.agents && state.model.agentsSelected) {
        state.model.agents = filterSelected(
          state.model.agents,
          state.model.agentsSelected,
        );
      }

      if (state.model.supervisers && state.model.supervisersSelected) {
        state.model.supervisers = filterSelected(
          state.model.supervisers,
          state.model.supervisersSelected,
        );
      }
    },
    SET_SELECTED_ARRAY(state, { sourceArray, targetArray, item }) {
      let index = -1;
      let isAgent = false;
      if (sourceArray == 'campagnes') {
        index = state.model[sourceArray].findIndex(
          (itemInArray) => itemInArray.campagneId === item.campagneId,
        );
      } else {
        isAgent = true;
        index = state.model[sourceArray].findIndex(
          (itemInArray) => itemInArray === item,
        );
      }
      if (isAgent) {
        let indexAgentToRemove = -1;
        indexAgentToRemove = state.model.agentsToRemove.findIndex(
          (itemInAgentToRemove) => itemInAgentToRemove === item,
        );
        if (indexAgentToRemove != -1)
          state.model.agentsToRemove.splice(indexAgentToRemove, 1);
      }
      state.model[targetArray].push(item);
      state.model[sourceArray].splice(index, 1);
    },
    REMOVE_SELECTED(state, { sourceArray, targetArray, itemId }) {
      let index = -1;

      if (sourceArray == 'campagnes') {
        index = state.model[targetArray].findIndex(
          (itemInArray) => itemInArray.campagneId === itemId,
        );
      } else {
        index = state.model[targetArray].findIndex(
          (itemInArray) => itemInArray.id === itemId,
        );
      }
      state.model[sourceArray].push(state.model[targetArray][index]);
      if (sourceArray != 'campagnes')
        state.model.agentsToRemove.push(state.model[targetArray][index]);
      state.model[targetArray].splice(index, 1);
    },
    EDIT_ELEMENT(state, payload) {
      let collection = [];
      state.collection.forEach((element) => {
        if (element.id == payload.id) {
          collection.push(payload);
        } else {
          collection.push(element);
        }
      });
      state.collection = collection;
    },
    SET_IS_FETCHING(state, payload) {
      state.isFetchingDataLoading = payload;
    },
    SET_TOP_FLOP_COLLECTION(state, payload) {
      state.topFlopCollection = payload;
    },
    SET_TOP_FLOP(state, payload) {
      state.model.topFlop = payload;
    },
    SET_NEW_COLLECTION(state, payload) {
      const { agentToRemove, teamId } = payload;
      const teamIndex = state.collection.findIndex(
        (team) => team.id === teamId,
      );
      if (teamIndex !== -1) {
        const team = state.collection[teamIndex];
        const agentIdsToRemove = agentToRemove.map((agent) => agent.id);
        team.agents = team.agents.filter(
          (agent) => !agentIdsToRemove.includes(agent.id),
        );
        state.collection[teamIndex] = team;
      }
    },
    SET_NEW_COLLECTION_AGENT(state, payload) {
      const { agentToRemove } = payload;

      agentToRemove.forEach((agent) => {
        const teamContainingAgentIndex = state.collection.findIndex(
          (team) => team.id === agent.team.id,
        );

        if (teamContainingAgentIndex !== -1) {
          const teamContainingAgent =
            state.collection[teamContainingAgentIndex];
          teamContainingAgent.agents = teamContainingAgent.agents.filter(
            (a) => a.id !== agent.id,
          );
          state.collection[teamContainingAgentIndex] = teamContainingAgent;
        }
      });
    },
    SET_IS_IN_DASHBOARD(state, payload) {
      state.model.inDashboard = payload;
    },
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    resetState(context, payload) {
      context.commit('RESET_STATE', context.state.defaultModel);
    },
    resetFilter(context) {
      context.commit('RESET_FILTER');
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    resetPagination(context) {
      context.commit('RESET_PAGINATION');
    },

    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    setCampagnesIds(context) {
      context.commit('SET_CAMPAGNES_IDS');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    resetErrors(context) {
      context.commit('RESET_ERRORS');
    },
    setErrorsByName(context, payload) {
      context.commit('SET_ERRORS_BY_NAME', payload);
    },
    setIsInDashboard(context, payload) {
      context.commit('SET_IS_IN_DASHBOARD', payload);
    },
    async getData(context) {
      context.commit('SET_IS_LOADING', true);
      if (context.state.cancel) {
        context.state.cancel();
      }
      const cancelSource = CancelToken.source();
      if (!context.state.model.inDashboard) {

        context.commit('SET_CANCEL', cancelSource.cancel);
      }
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            ...(context.state.filter.withPagination === 1 &&
            context.state.pagination
              ? {
                  per_page: context.state.pagination.per_page,
                  page: context.state.pagination.current_page,
                }
              : {}),
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: !context.state.model.inDashboard
            ? cancelSource.token
            : null,
        };

        axios
          .get('/team', config)
          .then(async (res) => {
            await context.commit('SET_FILTRED_TEAM_COLLECTION', [])
            await context.commit('SET_FILTRED_TEAM_COLLECTION', res.data.data.data)
            await context.commit('SET_COLLECTION', res.data.data.data);
            context.commit('SET_IS_LOADING', false);
            resolve(res.data.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) context.commit('SET_IS_LOADING', false);
          });
      });
    },

    async createTeam(context) {
      context.commit('SET_IS_LOADING_BUTTON', true);
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      context.commit('RESET_FILTER');
      context.commit('RESET_PAGINATION');

      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            ...(context.state.filter.withPagination === 1 &&
            context.state.pagination
              ? {
                  per_page: context.state.pagination.per_page,
                  page: context.state.pagination.current_page,
                }
              : {}),
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .post('/team', context.state.model, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            await context.commit('RESET_MODEL');
            context.commit('SET_IS_LOADING_BUTTON', false);

            resolve(res.data.data);
          })
          .catch((err) => {
            if (isCancel(err)) {
            } else {
              context.commit('SET_ERRORS', err.response.data.message.errors);
              if (err.response.status === 422) {
                context.commit('SET_ERRORS', err.response.data.errors);
              }
              context.commit('SET_IS_LOADING_BUTTON', false);

              //reject(err);
            }
          });
      });
    },
    async updateTeam(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }
      context.commit('SET_IS_LOADING_BUTTON', true);

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .put('/team/' + context.state.model.id, context.state.model, config)
          .then(async (res) => {
            await context.commit('EDIT_ELEMENT', res.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            context.commit('SET_IS_LOADING_BUTTON', false);

            resolve(true);
          })
          .catch((err) => {
            if (!isCancel(err)) {
              context.commit('SET_ERRORS', err.response.data.message.errors);
              context.commit('SET_IS_LOADING_BUTTON', false);

              //reject(err);
            }
          });
      });
    },
    async deleteTeam(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            keyword: context.state.filter.keyword,
            withPagination: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .delete('/team/delete/' + context.state.model.id, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            //reject(err);
          });
      });
    },
    async getTopFlopTeamData(context, payload) {
      context.commit('SET_IS_FETCHING', true);
      if (context.state.cancel) {
        context.state.cancel();
      }
      const cancelSource = CancelToken.source();
      context.commit('SET_CANCEL', cancelSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDateForCompare = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };

        const startDateForCompare = formatDateForCompare(oneWeekAgo);
        const endDateForCompare = formatDateForCompare(today);
        let page = context.state.pagination.current_page;
        if (context.state.pagination.last_page > page) {
          page = context.state.pagination.current_page + 1;
        }
        const config = {
          per_page: context.state.pagination.per_page,
          params: {
            ...context.state.filter,
            page: page,
            ...context.state.filter,
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDateForCompare,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDateForCompare,
            startOldDate: context.state.filter?.startOldDate
              ? context.state.filter?.startOldDate
              : startDateForCompare,
            endOldDate: context.state.filter?.endOldDate
              ? context.state.filter?.endOldDate
              : endDateForCompare,
            campagneId: payload?.campagneId,
            gridId: payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/team/topFlop/' + context.state.model.topFlop, config)
          .then(async (res) => {
            await context.commit('SET_TOP_FLOP_COLLECTION', res.data.data.data);
            if (payload.dashbordData) {
              let mergedArray = [
                ...payload.dashbordData,
                ...res.data.data.data,
              ];
              await context.commit('SET_TOP_FLOP_COLLECTION', mergedArray);
            } else {
              await context.commit(
                'SET_TOP_FLOP_COLLECTION',
                res.data.data.data,
              );
            }
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            context.commit('SET_IS_FETCHING', false);
            resolve(res.data.data);
          })
          .catch((err) => {
            //context.commit('SET_IS_FETCHING', false);
            //reject(err);
          });
      });
    },
    setTopFlop(context, payload) {
      context.commit('SET_TOP_FLOP', payload);
    },
  },
  getters: {
    getState: (state) => state,
    getModel: (state) => state.model,
    getAgentToRemove: (state) => state.AgentToRemove,
    getPagination: (state) => state.pagination,
    getCollection: (state) => state.collection,
    getTeamWithoutPagination: (state) => state.collectionIdWithName,
    getFilter: (state) => state.filter,
    getErrors: (state) => state.errors,
    getTopFlopCollection: (state) => state.topFlopCollection,
    getIsFetching: (state) => state.isFetchingDataLoading,
    getInDashboardState: (state) => state.model.inDashboard,
    getFiltredTeamCollection: (state) => state.filtrdTeamCollection,

  },
};
