export default {
    model: {
        title: null,
        status: null,
        callType: null,
        callCenterId: null,
        startDate: null,
        campagneId: null,
        memberCalibrations: [],
        recordCalibrations: [],
        records: [],
        calibrationEmails: {
          toInsert: [],
        },
      },
      collection: [],
      pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
      },
      filter: {
        keyword: '',
        startDate: null,
        endDate: null,
        sortOrder: null,
        sortKey: null,
        trashed: false
      },
      errors: null,
      isFetchingData: false,
      cancel: null,
  }