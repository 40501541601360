import Vue from 'vue';
import Vuex from 'vuex';
import UserModule from './modules/user.js';
import AgentModule from './modules/agent.js';
import callCenter from './modules/callCenter.js';
import teamModule from './modules/team.js';
import campaign from './modules/campaign.js';
import exempleModule from './modules/exemple.js';
import settingsModule from './modules/settings.js';
import gridModule from './modules/grid.js';
import categoryModule from './modules/category.js';
import itemModule from './modules/item.js';
import noteModule from './modules/note.js';
import recordModule from './modules/record.js';
import calibrage from './modules/calibrage.js';
import detailsCalibrage from './modules/detailscalibrage.js';
import qualification from './modules/qualification.js';
import notifications from './modules/notifications.js';
import training from './modules/training.js';
import evaluation from './modules/evaluation.js';
import appModule from './modules/app.js';
import usersModule from './modules/user.js';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    user: UserModule,
    agent: AgentModule,
    callCenter: callCenter,
    team: teamModule,
    campaign: campaign,
    exemple: exempleModule,
    settings: settingsModule,
    grid: gridModule,
    category: categoryModule,
    item: itemModule,
    note: noteModule,
    record: recordModule,
    calibrage: calibrage,
    detailsCalibrage: detailsCalibrage,
    qualification: qualification,
    notifications: notifications,
    training: training,
    evaluation: evaluation,
    app: appModule,
  },
});
