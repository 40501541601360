import { axios, CancelToken, isCancel } from '../services/axios.js';

export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: '',
      status: 1,
      idCallCenter: '',
      acounts: [],
      toModifyAcounts: [],
      updated_at: '',
      toInsert: [],
      toUpdate: [],
      toDelete: [], //onlyId
      // the rest of itgetDataCollectionTeams
    },
    collection: [],
    collectionIdWithName: [],
    collectionForTeam: [],
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      page: 1,
    },
    filter: {
      keyword: '',
      withPagination: 1,
      startDate: null,
      endDate: null,
      onlyIdName: 0,
      sortOrder: null,
      sortKey: null,
      trashed: false,
      callCenters: [],
      agents: [],
      teams: [],
    },
    errors: null,
    cancel: null,
    isFetchingDataLoading: false,
    LoadingButton: false,
  },
  mutations: {
    ADD(state, payload) {
      // this mutation will add a new record to collection
      state.collection = [payload, ...state.collection];
      //state.collection = state.collection.unshift(payload);
      /* if (state.collection.length > state.pagiantion.per_page) {
        state.collection = state.collection.splice(
          state.collection.length - 1,
          1,
        );
      } */
    },
    DELETE(state, payload) {
      // this mutation will delete record by id from collection
      state.collection = state.collection.filter(
        (record) => record.id != payload.id,
      );
    },
    UPDATE() {},
    SET_MODEL(state, payload) {
      state.model = payload;
      state.model.toModifyAcounts = payload.acounts;
    },
    SET_IS_LOADING(state, payload) {
      state.isFetchingDataLoading = payload;
    },
    SET_BUTTON_LOADING(state, payload) {
      state.LoadingButton = payload;
    },

    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        status: 1,
        idCallCenter: '',
        acounts: [],
        updated_at: '',
        toModifyAcounts: [],
        toInsert: [],
        toUpdate: [],
        toDelete: [], //onlyId
        // the rest of items
      };
    },
    RESET_ERRORS(state) {
      state.errors = null;
    },

    RESET_FILTER(state) {
      state.filter = {
        keyword: '',
        withPagination: 1,
        startDate: null,
        endDate: null,
        onlyIdName: 0,
        sortOrder: null,
        sortKey: null,
        trashed: false,
        callCenters: [],
        agents: [],
        teams: [],
      };
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
      };
    },
    SET_COLLECTION(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.collection = payload;
    },
    SET_COLLECTION_ID_WITH_NAME(state, payload) {
      // this mutation will store the fetched data from server in collections state
      state.collectionIdWithName = payload;
    },
    SET_COLLECTION_TEAM(state, payload) {
      if (payload.campagnes) {
        state.collectionForTeam.campagnes = payload.campagnes;
      }
      if (payload.callCentersSelected) {
        state.collectionForTeam.agents = payload.callCentersSelected.agents;
        state.collectionForTeam.agents.forEach((element) => {
          element.completeName = element.firstName + ' ' + element.lastName;
        });
        state.collectionForTeam.supervisers =
          payload.callCentersSelected.supervisers;
        state.collectionForTeam.supervisers.forEach((element) => {
          element.completeName = element.firstName + ' ' + element.lastName;
        });
      }
      if (payload.agents || payload.supervisers) {
        state.agents = payload.agents;
        state.collectionForTeam.agents.forEach((element) => {
          element.completeName = element.firstName + ' ' + element.lastName;
        });
        state.collectionForTeam.supervisers = payload.supervisers;
        state.collectionForTeam.supervisers.forEach((element) => {
          element.completeName = element.firstName + ' ' + element.lastName;
        });
      }
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_ERRORS_BY_NAME(state, payload) {
      state.errors = { ...state.errors, ...payload };
    },
    SET_CANCEL(state, cancel) {
      state.cancel = cancel;
    },
    MODIFY_ACOUNT(state, payload) {
      let acounts = [];
      state.model.toModifyAcounts.forEach((element) => {
        if (element.id == payload.id) {
          acounts.push(payload);
        } else {
          acounts.push(element);
        }
      });
      state.model.toModifyAcounts = acounts;
    },
    PUSH_ACOUNTS(state, payload) {
      payload.forEach((element) => {
        state.model.acounts.push(element);
      });
    },
    PUSH_ACOUNTS_TO_INSERT(state, payload) {
      state.model.toInsert = payload;
    },
    SET_TO_UPDATE(state, payload) {
      state.model.toUpdate = payload;
    },
    SET_TO_DELETE(state, payload) {
      if (
        !Object.prototype.hasOwnProperty.call(state.model, 'toDelete') ||
        !Array.isArray(state.model.toDelete)
      ) {
        state.model.toDelete = [];
      }
      // Create a deep copy of state.model.acounts
      const collectionsCopy = JSON.parse(JSON.stringify(state.collection));

      // Now you can push the payload into the toDelete array
      state.model.toDelete.push(payload);

      // Additionally, you might want to update other properties in state.model if needed
      state.model.acounts = state.model.acounts.filter(
        (record) => record.id !== payload,
      );
      state.model.toModifyAcounts = state.model.toModifyAcounts.filter(
        (record) => record.id !== payload,
      );
      if (state.model.toUpdate) {
        state.model.toUpdate = state.model.toUpdate.filter(
          (record) => record.id !== payload,
        );
      }
      state.collection = collectionsCopy;
    },
    EDIT_ELEMENT(state, payload) {
      let collection = [];
      state.collection.forEach((element) => {
        if (element.id == payload.id) {
          collection.push(payload);
        } else {
          collection.push(element);
        }
      });
      state.collection = collection;
    },
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    resetPagination(context) {
      context.commit('RESET_PAGINATION');
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    setErrorsByName(context, payload) {
      context.commit('SET_ERRORS_BY_NAME', payload);
    },
    resetErrors(context) {
      context.commit('RESET_ERRORS');
    },
    resetFilter(context) {
      context.commit('RESET_FILTER');
    },
    pushAcounts(context, payload) {
      context.commit('PUSH_ACOUNTS', payload);
    },
    pushAcountsToInsert(context, payload) {
      context.commit('PUSH_ACOUNTS_TO_INSERT', payload);
    },
    modifyAcount(context, payload) {
      context.commit('MODIFY_ACOUNT', payload);
    },
    setToUpdate(context, payload) {
      context.commit('SET_TO_UPDATE', payload);
    },
    setToDelete(context, payload) {
      context.commit('SET_TO_DELETE', payload);
    },
    async createCallCenter(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      context.commit('RESET_FILTER');
      context.commit('RESET_PAGINATION');
      context.commit('SET_BUTTON_LOADING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            ...(context.state.filter.withPagination === 1 &&
            context.state.pagination
              ? {
                  per_page: context.state.pagination.per_page,
                  page: context.state.pagination.current_page,
                }
              : {}),
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        const data = {
          ...context.state.model,
          ['acountsId']: context.state.model.acounts.map((item) => item.id),
        };
        axios
          .post('/callCenter', data, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            await context.commit('RESET_MODEL');
            context.commit('SET_BUTTON_LOADING', false);

            resolve(res.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) {
              context.commit('SET_ERRORS', err.response.data.errors);
              context.commit('SET_BUTTON_LOADING', false);
            }

            //reject(err);
          });
      });
    },
    async updateCallCenter(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      context.commit('SET_ERRORS', null);
      context.commit('SET_BUTTON_LOADING', true);

      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .put(
            '/callCenter/' + context.state.model.id,
            {
              ...context.state.model,
              ['acountsId']: context.state.model.acounts
                .filter((acount) => acount.new == true)
                .map((item) => item.id),
            },
            config,
          )
          .then(async (res) => {
            await context.commit('EDIT_ELEMENT', res.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            await context.commit('RESET_MODEL');
            context.commit('SET_BUTTON_LOADING', false);

            resolve(res.data.data);
          })
          .catch((err) => {
            if (err && err.resonse) {
              context.commit('SET_ERRORS', err.response.data.message.errors);
            }
            context.commit('SET_BUTTON_LOADING', false);

            reject(err.response.data);
          });
      });
    },
    async getData(context) {
      context.commit('SET_IS_LOADING', true);

      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            ...(context.state.filter.withPagination === 1 &&
            context.state.pagination
              ? {
                  per_page: context.state.pagination.per_page,
                  page: context.state.pagination.current_page,
                }
              : {}),
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/callCenter', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (context.state.filter.withPagination === 1) {
              if (res.data.pagination) {
                await context.commit('SET_PAGINATION', res.data.pagination);
              }
            } else {
              await context.commit('RESET_MODEL');
              await context.commit('RESET_FILTER');
            }
            context.commit('SET_IS_LOADING', false);

            resolve(res.data.data.data);
          })
          .catch((err) => {
            if (!isCancel(err)) context.commit('SET_IS_LOADING', false);

            //reject(err);
          });
      });
    },
    async getCollectionCallCentersCampagnes(context, { id }) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      return await new Promise((resolve) => {
        const config = {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/getallcallcentercampagnes', config)
          .then(async (res) => {
            await context.commit(
              'SET_COLLECTION_ID_WITH_NAME',
              res.data.data.data.callCenters,
            );
            await context.commit('SET_COLLECTION_TEAM', res.data.data.data);
            resolve(res.data.data.data);
          })
          .catch(() => {
            //if (!isCancel(err)) reject(err);
          });
      });
    },
    async getCollectionIdWithName(context) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/getallidwithname', config)
          .then(async (res) => {
            await context.commit(
              'SET_COLLECTION_ID_WITH_NAME',
              res.data.data.data,
            );
            resolve(res.data.data.data);
          })
          .catch(() => {
            //if (!isCancel(err)) reject(err);
          });
      });
    },
    async getDataCollectionTeam(context, { id }) {
      if (context.state.cancel) {
        context.state.cancel();
      }

      const cancelSource = CancelToken.source();

      context.commit('SET_CANCEL', cancelSource.cancel);

      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: cancelSource.token,
        };
        axios
          .get('/showCampagne/' + id, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION_TEAM', res.data.data.data);
            resolve(res.data.data);
          })
          .catch(() => {
            //if (!isCancel(err)) reject(err);
          });
      });
    },
    async deleteCallCenter(context, payload) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            keyword: context.state.filter.keyword,
            withPagination: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          data: { ids: payload },
        };
        axios
          .delete('/callCenter/delete/' + context.state.model.id, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_ERRORS', err.response.data.message.errors);
            //reject(err);
          });
      });
    },
    async getCallCenterStatistics(context, payload) {
      // if (context.state.cancel) {
      //   context.state.cancel();
      // }
      // const cancelSource = CancelToken.source();
      // context.commit('SET_CANCEL', cancelSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDate = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        const startDate = formatDate(oneWeekAgo);
        const endDate = formatDate(today);
        const config = {
          params: {
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDate,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDate,
            ...context.state.filter,
            campagneId: payload?.campagneId,
            gridId: payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
           // cancelToken: cancelSource.token,
          },
        };
        axios
          .get('/getCallCenterStatistics', config)
          .then(async (res) => {
            // await context.commit('SET_COLLECTION', res.data.data.data);
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING', false);
            // reject(err);
          });
      });
    },
    async getTeamStatistics(context, payload) {
      if (context.state.cancel) {
        context.state.cancel();
      }
      const cancelSource = CancelToken.source();
      context.commit('SET_CANCEL', cancelSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDate = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        const startDate = formatDate(oneWeekAgo);
        const endDate = formatDate(today);

        const config = {
          params: {
            startNewDate: context.state.filter?.startNewDate
            ? context.state.filter?.startNewDate
            : startDate,
          endNewDate: context.state.filter?.endNewDate
            ? context.state.filter?.endNewDate
            : endDate,
            ...context.state.filter,
            campagneId: payload?.campagneId,
            gridId: payload?.gridSelected?.id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
           cancelToken: cancelSource.token,
          },
        };
        axios
          .get('/getTeamStat', config)
          .then(async (res) => {
            // await context.commit('SET_COLLECTION', res.data.data.data);
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING', false);
            // reject(err);
          });
      });
    },

    updateAcountStatus(context, payload) {
      const acounts = [];
      for (let acount of context.state.model.acounts) {
        if (acount.id == payload.id) {
          acounts.push({
            ...acount,
            ['status']: payload.status,
          });
        } else {
          acounts.push(acount);
        }
      }
      context.commit('SET_MODEL', {
        ...context.state.model,
        ['acounts']: acounts,
      });
    },
    updateAcount(context, payload) {
      const acounts = [];
      for (let acount of context.state.model.acounts) {
        if (acount.id == payload.id) {
          acounts.push(payload);
        } else {
          acounts.push(acount);
        }
      }
      context.commit('SET_MODEL', {
        ...context.state.model,
        ['acounts']: acounts,
      });
    },
    deleteAcount(context, payload) {
      context.commit('SET_MODEL', {
        ...context.state.model,
        ['acounts']: context.state.model.acounts.filter(
          (acc) => acc.id != payload,
        ),
      });

      let collection = [];
      for (let item of context.state.collection) {
        if (item.acounts.find((acc) => acc.id == payload)) {
          collection.push({
            ...item,
            ['acounts']: item.acounts.filter((acc) => acc.id != payload),
          });
        } else {
          collection.push(item);
        }
      }
      context.commit('SET_COLLECTION', collection);
    },

    async getPercentOfSituations(context, payload) {
      // if (context.state.cancel) {
      //   context.state.cancel();
      // }
      // const cancelStatSource = CancelToken.source();
      // context.commit('SET_CANCEL', cancelStatSource.cancel);
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDate = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        const startDate = formatDate(oneWeekAgo);
        const endDate = formatDate(today);
        const config = {
          params: {
            startNewDate: context.state.filter?.startNewDate
              ? context.state.filter?.startNewDate
              : startDate,
            endNewDate: context.state.filter?.endNewDate
              ? context.state.filter?.endNewDate
              : endDate,
            ...context.state.filter,
            campagneId: payload?.campagneId,
            gridId: payload?.gridSelected?.id,
            situationType: payload.situationType,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          //  cancelToken: cancelStatSource.token,
          },
        };
        axios
          .get('/getSituation', config)
          .then(async (res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING', false);
            // reject(err);
          });
      });
    },
  },
  getters: {
    getState: (state) => state,
    getModel: (state) => state.model,
    getPagination: (state) => state.pagination,
    getCollection: (state) => state.collection,
    getIdWithName: (state) => state.collectionIdWithName,
    getCollectionTeam: (state) => state.collectionForTeam,
    getFilter: (state) => state.filter,
    getErrors: (state) => state.errors,
  },
};
