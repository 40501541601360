import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locale/en.json';
import fr from './locale/fr.json';
Vue.use(VueI18n);
const languages = {
  en,
  fr,
};
export default new VueI18n({
  locale: 'fr',
  messages: languages,
});
