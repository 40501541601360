import defaultState from '../defaultState/index.js';
import { axios, CancelToken, isCancel } from '../services/axios.js';

export default {
  namespaced: true,
  state: { ...defaultState.record },
  mutations: {
    UPDATE(state, payload) {
      let index = state.collection.findIndex((el) => el.id === payload.id);
      state.collection.splice(index, 1, payload);
    },

    ADD(state, payload) {
      state.collection.unshift(payload);
    },
    DELETE() {
      // this mutation will delete record by id from collection
    },

    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: null,
        originalName: null,
        linkRegistration: null,
        qualificationId: null,
        qualificationIA: null,
        speatchTranscription: null,
        speatchPonctuee: null,
        campagneId: null,
        agentId: null,
        gridId: null,
        file: null,
        status: 'in_progress',
        grid: [],
        pourcentage_ia: 0,
        pourcentage_scrib: 0,
        evaluation_user: {
          score_category: [],
        },
        audio_analysis: {
          agent_speech_percentage: null,
          prospect_speech_percentage: null,
          silence_percentage: null,
          total_audio_duration: null,
          total_segment_duration_left: null,
          total_segment_duration_right: null,
          total_silence_duration: null,
        },
        segmentsEmotion: [],
        segmentsLangue: null,
        segmentsLangueEtranger: null,
        insultingWords: []

      };
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    RESET_FILTER(state) {
      state.filter = {
        keyword: '',
        startDate: null,
        endDate: null,
        withPagination: 1,
      };
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_ACTIVE_RECORD(state, payload) {
      state.activeRecord = payload;
    },
    SET_SELECTED_RECORDS(state, payload) {
      state.selectedRecords = payload;
    },
    RESET_ACTIVE_RECORD(state) {
      state.activeRecord = {
        id: null,
        name: null,
        originalName: null,
        linkRegistration: null,
        qualificationId: null,
        qualificationIA: null,
        speatchTranscription: null,
        speatchPonctuee: null,
        campagneId: null,
        agentId: null,
        gridId: null,
        file: null,
        grid: [],
        pourcentage_ia: 0,
        pourcentage_scrib: 0,
        audio_analysis: {
          agent_speech_percentage: null,
          prospect_speech_percentage: null,
          silence_percentage: null,
          total_audio_duration: null,
          total_segment_duration_left: null,
          total_segment_duration_right: null,
          total_silence_duration: null,
        },
        segmentsEmotion: [],
        segmentsLangue: null,
        segmentsLangueEtranger: null,
        insultingWords: [],
        overlapping_result: {
          overlapping_result: {
            overlapping_segments: [],
            total_duration: null,
            overlapping_duration: null,
            overlapping_percentage: null,
            result_audio_path: null,
            request_id: null
          }
        }
      };
    },
    RESET_SELECTED_RECORDS(state) {
      state.selectedRecords = [];
    },
    SET_IS_FETCHING_RECORDS(state, payload) {
      state.isfetchingRecords = payload;
    },
    SET_GET_DATA_SOURCE(state, payload) {
      state.getDataSource = payload;
    },
    SET_NAVIGATION_FROM(state, payload) {
      state.navigationFrom = payload;
    },
    RESET_STATE(state, intialState) {
      Object.keys(intialState).forEach((key) => {
        state[key] = intialState[key];
      });
    },
    SET_PARENT_RECORD(state, payload) {
      state.activeParentRecord = payload
    },
    SET_SELECTED_NOTE(state, payload) {
      state.selectedNote = payload
    }
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },

    setSelectedRecords(context, payload) {
      context.commit('SET_SELECTED_RECORDS', payload);
    },
    resetSelectedRecords(context) {
      context.commit('RESET_SELECTED_RECORDS');
    },
    async getData(context) {
      return await new Promise((resolve, reject) => {
        if (context.state.getDataSource) {
          context.commit(
            'SET_GET_DATA_SOURCE',
            context.state.getDataSource.cancel(
              'Request canceled due to a new request',
            ),
          );
        }

        context.commit('SET_GET_DATA_SOURCE', CancelToken.source());
         const config = {
          params: {
            ...context.state.filter,
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          cancelToken: context.state.getDataSource.token,
        };
        context.commit('SET_IS_FETCHING_RECORDS', true);
        axios
          .get('/record', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', [
              ...context.state.collection,
              ...res.data.data.data,
            ]);
            context.commit('SET_IS_FETCHING_RECORDS', false);
            if (context.state.filter.keyword != ''  ) {
              context.commit('RESET_ACTIVE_RECORD');
            }
            
            if (
              res.data.data.data.length > 0 &&
              context.state.activeRecord.id == null
            ) {
               if(context.state.activeRecord.id == null){
                await context.commit(
                  'SET_ACTIVE_RECORD',
                  context.state.collection[0],
                );
                await context.commit(
                  'SET_PARENT_RECORD',
                  context.state.collection[0],
                );
               }
             
            }
            if (
              res.data.data.data.length > 0 &&
              context.state.filter.keyword != ''
            ) {
              if(context.state.activeRecord.id == null){
                
              await context.commit(
                'SET_ACTIVE_RECORD',
                context.state.collection[0],
              );
              await context.commit(
                'SET_PARENT_RECORD',
                context.state.collection[0],
              );
            }
            }

            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            resolve(true);
          })
          .catch((error) => {
            if (isCancel(error)) {
              context.commit('SET_IS_FETCHING_RECORDS', true);
              // reject(error);
            } else {
              context.commit('SET_IS_FETCHING_RECORDS', false);
              // reject(error);
            }
          });
      });
    },
    async createRecord(context) {
      context.state.filter.keyword = '';
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .post('/record', context.state.model, config)
          .then(() => {
            /* if (
              context.state.collection.length >=
              context.state.pagination.per_page
            ) {
              context.state.collection.splice(-1, 1),
                context.commit('ADD', res.data.data);
            } else {
              context.commit('ADD', res.data.data);
            }
  
            context.commit('SET_PAGINATION', res.data.pagination); */

            context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(false);
          });
      });
    },
    setActiveRecord(context, payload) {
      context.commit('SET_ACTIVE_RECORD', payload);
    },
    async uploadRecord(context, onUploadProgress) {
      context.state.filter.keyword = '';
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (onUploadProgress) {
              onUploadProgress(progressEvent);
            }
          },
        };
        const formData = new FormData();
    
        formData.append('originalName', context.state.model.originalName);
        formData.append('file', context.state.model.file);
        formData.append('dateTime', context.state.model.dateTime);
        formData.append('callCenterId', context.state.model.callCenterId);
        formData.append('agentId', context.state.model.agentId);
        formData.append('gridId', context.state.model.gridId);
        formData.append('qualificationId', context.state.model.qualificationId);
        formData.append('duration', context.state.model.duration);
        
        axios
          .post('/record/uploadRecord', formData, config)
          .then((res) => {
            context.commit('ADD', res.data.data.data);
            context.commit('SET_ACTIVE_RECORD', res.data.data.data);
            context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response && err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(err);
          });
      });
    },    
    async uploadSubRecord(context, onUploadProgress) {
      context.state.filter.keyword = '';
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (onUploadProgress) {
              onUploadProgress(progressEvent);
            }
          },
        };
        const formData = new FormData();

        formData.append('file', context.state.model.file);
        formData.append('dateTime', context.state.model.dateTime);
        formData.append('callCenterId', context.state.model.callCenterId);
        formData.append('agentId', context.state.model.agentId);
        formData.append('gridId', context.state.model.gridId);
        formData.append('qualificationId', context.state.model.qualificationId);
        formData.append('duration', context.state.model.duration);
        formData.append('recordId', context.state.activeRecord.id);
        axios
          .post('/record/uploadSubRecord', formData, config)
          .then((res) => {
            let records = []
            for (let record of context.state.collection) {
              if (record.id == context.state.activeRecord.id) {
                records.push(res.data.data.data)
              } else {
                records.push(record)
              }
            }
            context.commit('SET_COLLECTION', records);
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(err);
          });
      });
    },
    //create or update note eval for current record
    async noteEvalManual(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .post('/scoring-evaluation', payload, config)
          .then((res) => {
            if (
              res.data != undefined &&
              res.data.data != undefined &&
              res.data.data.data != undefined
            ) {
              context.commit('SET_ACTIVE_RECORD', res.data.data.data);
              context.commit('SET_PARENT_RECORD', res.data.data.data);
              context.commit('UPDATE', res.data.data.data);
            }
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            reject(false);
          });
      });
    },
    async updateCommentaireNote(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .put('/commentaire-scoring-evaluation', payload, config)
          .then(() => { resolve(true) })
          .catch(() => { });
      });
    },

    //add or remove favory record
    async AddOrRemoveFavory(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .post('/record/favory', payload, config)
          .then(() => {
            let current_record = context.state.collection.find((element) => element.id == payload.recordId);
            current_record.is_favory = !current_record.is_favory;
          })
          .catch(() => {

          });
      });
    },

    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    resetState(context) {
      context.commit('RESET_STATE', defaultState.record);
    },
    setCollection(context, payload) {
      context.commit('SET_COLLECTION', payload);
    },
    async getRecordById(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/record/' + payload, config)
          .then((res) => {
            resolve(res.data.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async recordTranscriptionFinished(context, payload) {
      const recordId = payload
      return await new Promise((resolve, reject) => {
        if (context.state.activeRecord.id === recordId) {
          context.dispatch('getRecordById', recordId)
            .then((data) => {
              context.commit('SET_ACTIVE_RECORD', data)
              resolve(true)
            })
        } else {
          reject(false)
        }
      });
    },
    async RecordPageIndex(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/record/' + payload, config)
          .then((res) => {
            resolve(res.data.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setNavigationFrom(context, payload) {
      context.commit('SET_NAVIGATION_FROM', payload)
    },
    updateRecordEvaluation(context, payload) {

      const records = [...context.state.collection]
      let newRecords = []
      for (let record of records) {
        if (record.id == payload.recordId) {
          context.commit('SET_ACTIVE_RECORD', { ...record, ['evaluation_user']: { ...payload.evaluationData, ['finished']: 1 } });
          newRecords.push({ ...record, ['evaluation_user']: { ...payload.evaluationData, ['finished']: 1 } })
        } else {
          newRecords.push(record)
        }
      }

      context.commit('SET_COLLECTION', newRecords)
    },
    setParentRecord(context, payload) {
      context.commit('SET_PARENT_RECORD', payload)
    },
    setSelectedNote(context, payload) {
      context.commit('SET_SELECTED_NOTE', payload)
    }
  },
  getters: {
    getPagination: (state) => state.pagination,
    getModel: (state) => state.model,
    getErrors: (state) => state.errors,
    getCollection: (state) => state.collection,
    getActiveRecord: (state) => state.activeRecord,
    getSelectedRecords: (state) => state.selectedRecords,
    getIsFetchingRecords: (state) => state.isfetchingRecords,
    getFilter: (state) => state.filter,
    getNavigationFrom: (state) => state.navigationFrom,
    getParentRecord: (state) => state.activeParentRecord,
    getSelectedNote: (state) => state.selectedNote
  },
};
