export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: '',
      score: '',
      meaning: '',
      itemId: null,
      status: false,
    },
    collection: [],
    errors: null,
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    UPDATE(state, payload) {
      this.state.model = [payload, ...state.model];
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        score: '',
        meaning: '',
        itemId: null,
        status: false,
      };
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
  },

  actions: {
    setNoteModel(context, payload) {
      
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    saveNote(context, payload) {
      context.commit('ADD', payload);
    },
    updateNoteModel(context, payload) {
      context.commit('UPDATE', payload);
    },
  },
  getters: {
    getNoteModel: (state) => state.model,
    getNoteCollection: (state) => state.collection,
    getErrors: (state) => state.errors,
  },
};
