<template>
  <div>
    <div class="form-field" :class="hasError ? 'fieldError' : 'mb-4'">
      <label class="control-label password">{{ label }}*</label>
      <input :type="showPassword ? 'text' : 'password'" @input="handleInput" />

      <div class="btn-show-hide">
        <button tabindex="-1"  @click="toggleShowPassword">
          <i
            :class="{
              'icon-show': showPassword,
              'icon-hide': !showPassword,
            }"
          ></i>
        </button>
      </div>
    </div>
    <div class="error mb-4 pt-2" v-if="hasError">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
    };
  },
  props: {
    name: String,
    label: String,
    hasError: Boolean,
    errorMessage: String,
    input: Function,
  },

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    emitValue() {
      this.$emit('input-value', this.name, this.value);
    },
    handleInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>
