<template>
  <button @click="WhenClicked" class="btn-primary">
    <LoaderBtn v-if="loading" />
    <template v-else>
      {{ text }}
    </template>
  </button>
</template>

<script>
//import Loader from '@/components/AnimationScribb/Loader.vue';
import LoaderBtn from '@/components/AnimationScribb/LoaderBtn.vue';
 
export default {
  components: {
    //Loader,
    LoaderBtn,
  },
  props: {
    text: String, // Label text
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    WhenClicked() {
      this.$emit('when-cliked'); // Emit a custom event to the parent component
    },
  },
};
</script>
