<template>
  <transition name="slide">
  <div v-if="isOpened2" :class="{ drawer: true}" class="show">
    <div class="header_drawer">
      <div class="title">
        <h4> {{ $globals.capitalizeFirstLetter($t("common.updateProfile")) }}</h4>
      </div>
      <div class="content-button btn-add">
        <button class="close mr-5" @click="handleClose">
          {{ $globals.capitalizeFirstLetter($t("common.cancel")) }}
          <i class="icon-exit"></i>
        </button>
        <Button
          :loading="loading"
          @when-cliked="handleOnSubmit"
          :text="$globals.capitalizeFirstLetter($t('common.save'))"
        ></Button>
      </div>
    </div>

    <div class="body_drawer">
      <div class="form-group-flex">
        <div class="form-field">
          <input-field
            class="mb-7"
            :label="$globals.capitalizeFirstLetter($t('common.lastName'))"
            :defaultValue="model.lastName"
            @input="
              (e) => {
                lastName=e.target.value
              }
            "
          />
        </div>
        <div class="form-field ml-4">
          <input-field
            class="mb-7"
            :label="$globals.capitalizeFirstLetter($t('common.firstName'))"
            :defaultValue="model.firstName"
            @input="
              (e) => {firstName= e.target.value
              }
            "
          />
        </div>
      </div>
{{  firstName}}
      <div class="form-group-flex">
        <div class="form-field">
          <input-field
            :disabled="true"
            class="mb-7"
            :label="$globals.capitalizeFirstLetter($t('common.email'))"
            :defaultValue="model.email"
            @input="
              (e) => {
                email= e.target.value
                }
            "
          />
        </div>
      </div>

      <div class="link-modif-psswd">
        <div class="add-comptes mt-4">
          <button @click="showPassword">
            <span class="mr-2"><i :class="toggleIcon"></i></span>
            {{
              $globals.capitalizeFirstLetter($t("common.update")) +
              " " +
              $t("common.password")
            }}
          </button>
        </div>
      </div>

      <div class="form-field mt-7 edit-psswd" v-if="showPasswd">
        <div class="form-field">
          <input-password
            class=""
            :label="$globals.capitalizeFirstLetter($t('common.oldPassword'))"
            @input="
              (e) => {
                this.password = e.target.value;
              }
            "
            :hasError="errors != null && errors.password != null"
            :errorMessage="
              errors && errors.password != null ? $t('errors.' + errors.password[0]) : ''
            "
          />
        </div>
       
      </div>
      <div class="form-field mb-2 edit-psswd" v-if="showPasswd">
        <div class="form-field">
          <input-password
            class="mb-7"
            :label="$globals.capitalizeFirstLetter($t('common.confirmPassword'))"
            @input="
              (e) => {
                this.newPassword = e.target.value;
              }
            "
            :hasError="errors != null && errors.newPassword != null"
            :errorMessage="
              errors && errors.newPassword != null
                ? $t('errors.' + errors.newPassword[0])
                : ''
            "
          />
        </div>
        <div class="form-field ml-4"></div>
      </div>
    </div>
    <div id="overlay-" @click="handleClose"></div>
  </div>
  </transition>
</template>

<script>
import InputField from "@/components/FormWidgets/InputField.vue";
import Button from "@/components/FormWidgets/Button.vue";
import { mapGetters, mapActions } from "vuex";
import InputPassword from "@/components/FormWidgets/InputPassword.vue";
import 'vue2-datepicker/index.css';
export default {
  components: {
    InputField,
    Button,
    InputPassword,
  },
  data() {
    return {
      loading: false,
      showDrawer: false,
      showPasswd: false,
      password: "",
      newPassword: "",
      firstName:"",
      lastName:"",
      email:""

    };
  },
 
  props: {
    isOpened2: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    ...mapActions("user", ["updateProfil", "setUser", "setErrors", "resetPasswordModel","resetModel"]),
    handleClose() {
      this.$emit("close");
      this.resetPasswordModel();
      this.showPasswd = !this.showPasswd;
    },
    close() {
      this.showDrawer = false;
      this.showPasswd = !this.showPasswd;
      this.resetPasswordModel();
    },

    showPassword() {
      this.showPasswd = !this.showPasswd;

    },
    handleOnSubmit() {
      let data = {
        firstName: this.firstName.trim()=='' ? this.getUser.firstName : this.firstName,
        lastName:this.lastName.trim()=='' ? this.getUser.lastName : this.lastName,
        pseudo:this.model.pseudo,
        phone: this.model.phone,
        birthDate: this.model.birthDate,
      };
      if (this.password !== "") {
        data.password = this.password;
      }

      if (this.newPassword !== "") {
        data.newPassword = this.newPassword;
      }
      this.updateProfil(data)
        .then(() => {

          this.handleClose();
        })
        .catch(() => {});
    },

    updateBirthDate(newDate) {
      this.$set(this.model, 'birthDate', `${newDate} ${this.birthDate}`);
    },
  },

  computed: {
    ...mapGetters("user", ["getUser", "getErrors"]),
    model() {
      return this.getUser;
    },

    toggleIcon() {
          return this.showPasswd ? 'icon-minus' : 'icon-plus';
        },
    errors() {
      return this.getErrors;
    },
    birthDate(){
      return this.model.birthDate ? this.model.birthDate.split(' ')[0] : null;
    }
  },
  watch: {
    password: function (newValue) {
      let errors = [];
      if (newValue == "") {
        errors.push("PASSWORD_REQUIRED_UNLESS");
      }
      if (errors.length > 0) {
        this.setErrors({
          ...this.getErrors,
          ["password"]: errors,
        });
      } else {
        const target = {};
        const source = this.errors;
        let returnedTarget = Object.assign(target, source);
        delete returnedTarget.password;
        setTimeout(() => {
          this.setErrors(returnedTarget);
        });
      }
    },
    newPassword: function (newValue) {
      let errors = [];
      if (newValue == "") {
        errors.push("NEW_PASSWORD_REQUIRED");
      } else if (newValue && newValue.length < 6) {
        errors.push("NEW_PASSWORD_MIN");
      }
      if (errors.length > 0) {
        this.setErrors({
          ...this.getErrors,
          ["newPassword"]: errors,
        });
      } else {
        const target = {};
        const source = this.errors;
        let returnedTarget = Object.assign(target, source);
        delete returnedTarget.newPassword;
        setTimeout(() => {
          this.setErrors(returnedTarget);
        });
      }
    },
  },
};
</script>
