import { axios } from '../services/axios.js';
export default {
  namespaced: true,
  state: {
    model: {
      adresseSftp: null,
      userNameSftp: null,
      portSftp: null,
      loginSftp: null,
      pathSftp: null,
      datePosition: 1,
      dateFormat: 'Ymd',
      qualificationPosition: 5,
      separatorCharacter: '_',
      identifiantPosition: 2,
    },
    collection: [],
    errors: [],
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    DELETE(state, payload) {
      state.collection = state.collection.filter(
        (configItem) => configItem.configurationId !== payload,
      );
    },
    UPDATE(state, payload) {
      let index = state.collection.findIndex(
        (x) => x.id === payload.configurationId,
      );
      state.collection.splice(index, 1, payload);
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        hermesId: null,
        firstName: null,
        lastName: null,
        callCenterId: null,
        roleId: null,
      };
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    async getSettingsData(context) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/configuration', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            resolve(res.data.data.data);
          })
          .catch((err) => {
            //reject(err);
          });
      });
    },
    async createOrUpdateSettings(context) {
      context.commit('SET_ERRORS', []);
      let dataToUpdateOrCreate = context.state.model;
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/configuration/saveOrUpdate', dataToUpdateOrCreate, config)
          .then(async (res) => {
            if (dataToUpdateOrCreate.id != null) {
              //update
              await context.commit('UPDATE', res.data.data.data);
            } else {
              await context.commit('ADD', res.data.data.data);
            }

            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            //reject(err);
          });
      });
    },
    async deleteSetting(context, id) {
      context.commit('DELETE', id);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .delete('/configuration/' + id, config)
          .then(async (res) => {
            await context.commit('DELETE', id);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
            //reject(err);
          });
      });
    },
  },
  getters: {
    getModel: (state) => state.model,
    getSettingsCollection: (state) => state.collection,
    getErrors: (state) => state.errors,
  },
};
