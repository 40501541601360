<template>
  <div class="form-field mb-4" :class="{ fieldError: hasError }">
    <label class="control-label" :for="id">{{ label }}</label>
    <input
      :name="name"
      :type="type"
      :id="id"
      v-model="inputValue"
      @input="handleInput"
      :disabled="disabled"
      @blur="handleBlur"
    />
    <div class="error mt-2" v-if="hasError">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    name: String,
    hasError: Boolean,
    errorMessage: String,
    disabled: Boolean,
    defaultValue: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    id: String,
    input: {
      type: Function,
      requried: false,
      default: () => {},
    },
    rules: Array,
  },
  data() {
    return {
      inputValue: this.defaultValue,
    };
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event);
    },
    handleBlur() {
      this.$emit('blur', this.inputValue);
    },
  },
  watch: {
    defaultValue(newValue) {
      this.inputValue = newValue;
    },
  },
};
</script>
