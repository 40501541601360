<template>
  <div class="dropdwon_menu">
    <button :class="{
      'dropdown-disabled': disabled && disabled == true,
      [btnclass]: true,
    }" :id="btnId">
      <i :class="iconLeft"></i>
      {{ text }}
      <i :class="icon" class="ml-3"></i>
    </button>
    <div :id="dropdownId" style="display: none">
      <div v-for="(item, index) of items" :key="index">
        <button  @click="
            () => {
              item.onClick();
              toggleDropdown();
            }
          "
          v-if="item.visible == true || item.visible == undefined"
          :class="{ 'item-disabled ': item.disabled && item.disabled == true }">
          <i :class="item.iconClass"></i>
          {{ item.label }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeButton: 0,
    };
  },
  props: {
    text: String,
    icon: String,
    iconLeft: String,
    btnId: String,
    btnclass: String,
    dropdownId: String,
    items: {
      type: Array,
      required: true,
    },
    disabled: Boolean,
    childFunctionAddAgent: Function,
    childFunctionImportMasse: Function,
    childFunctionArchiveMasse: Function,
  },
  methods: {
    callParentFunction1() {
      this.childFunctionAddAgent();
    },
    callParentFunction2() {
      this.childFunctionImportMasse();
    },
    callParentFunction3() {
      this.childFunctionArchiveMasse();
    },
    setActiveButton(buttonNumber) {
      this.activeButton = buttonNumber;
    },
    toggleDropdown() {
      const dropdown = document.getElementById(this.dropdownId);
      if (this.disabled == false) {
        if (dropdown.style.display === 'block') {
          dropdown.style.display = 'none';
        } else {
          dropdown.style.display = 'block';
        }
      }
    },
    handleDocumentClick(event) {
      const dropdown = document.getElementById(this.dropdownId);
      const btn = document.getElementById(this.btnId);
      if (
        dropdown &&
        btn &&
        !btn.contains(event.target) &&
        !dropdown.contains(event.target)
      ) {
        dropdown.style.display = 'none';
      }
    },
  },
  mounted() {
    document
      .getElementById(this.btnId)
      .addEventListener('click', this.toggleDropdown);
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    const btn = document.getElementById(this.btnId);
    if (btn) {
      btn.removeEventListener('click', this.toggleDropdown);
    }
    document.removeEventListener('click', this.handleDocumentClick);
  },
};
</script>