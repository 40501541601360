//import { axios } from '../services/axios.js';
export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: null,
      // the rest of items
    },
    collection: [],
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 2,
    },
  },
  mutations: {
    ADD() {
      // this mutation will add a new record to collection
    },
    DELETE() {
      // this mutation will delete record by id from collection
    },
    UPDATE() {
      // this mutation will update record by id from collection
    },
    SET_MODEL() {
      // this mutation will change the agent object value
    },
    SET_COLLECTION() {
      // this mutation will store the fetched data from server in collections state
    },
    SET_PAGINATION() {
      // this mutation will change the pagination state values
    },
  },
  actions: {},
  getters: {
    getPagination: (state) => state.pagination,
  },
};
