import { axios } from '../services/axios.js';
import store from './../../store';


export default {
  namespaced: true,
  state: {
    model: {
      title: null,
    },
    collection: [
      {
        calibration: {
          title: null,
        },
        scoring_categ: null,
        record: [],
        member_calibration: [],
        grid: null,
        evaluation_user: [],
      },
    ],
    compterendu: [
      {
        calibration: {
          title: null,
        },
        scoring_categ: null,
        record: [],
        member_calibration: [],
        grid: null,
        evaluation_user: [],
      },
    ],
    errors: null,
    isGuest: true,
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    // DELETE(state, payload) {
    //   state.collection = state.collection.filter(
    //     (record) => record.id != payload.id,
    //   );
    // },
    // UPDATE(state, payload) {
    //   let index = state.collection.findIndex((x) => x.id === payload.id);
    //   state.collection.splice(index, 1, payload);
    // },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        title: null,
      };
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_COMPTERENDU(state, payload) {
      state.compterendu = payload;
    },
    SET_IS_GUEST(state, payload) {
      state.isGuest = payload;
    },
  },
  actions: {
    setIsGuest(context, payload) {

      context.commit('SET_IS_GUEST', payload);
    },

    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },

    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    async getData(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/calibration-details-member/' + payload, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_IS_GUEST', res.data.data.data.is_guest)
            resolve(res.data.data.data)
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //create or update note eval for current record
    async noteEvalCalibrage(context, payload) {
      return await new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .post('/scoring-calibration', payload, config)
          .then((res) => {
            if (
              res.data != undefined &&
              res.data.data != undefined &&
              res.data.data.data != undefined
            ) {
              if (
                res.data.data.data.record != undefined &&
                res.data.data.data.record.length > 0
              ) {
                store.dispatch(
                  'record/setActiveRecord',
                  res.data.data.data.record[0],
                );
              }
              if (res.data.data.data.record_calibration[0] != undefined && res.data.data.data.record_calibration[0].record[0] != undefined) {
                resolve(res.data.data.data.record_calibration[0].record[0])

              }
              context.commit('SET_COLLECTION', res.data.data.data);
            }
          })
          .catch((err) => {
            if (
              err.response.status != undefined &&
              err.response.status === 422
            ) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
          });
      });
    },

    async updateCommentaireNoteCalibration(context, payload) {
      return await new Promise(() => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .put('/commentaire-scoring-evaluation-calibration', payload, config)
          .then(() => { })
          .catch(() => { });
      });
    },

    async getCompterenduDetails(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/calibration-compte-rendu/' + payload, config)
          .then(async (res) => {
            await context.commit('SET_COMPTERENDU', res.data.data.data);
            resolve(res.data.data.data)
          })
          .catch((err) => {
            //reject(err);
          });
      });
    },
    async updateRecordCalibration(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .put('/record-calibration/' + payload.id, payload.data, config)
          .then(async (res) => {
            resolve(res.data.data.data)
          })
          .catch((err) => {
            //reject(err);
          });
      });
    },
    async updateRecordCalibrationGuest(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .put('/record-calibration-guest/' + payload.id, payload.data, config)
          .then(async (res) => {
            resolve(res.data.data.data)
          })
          .catch((err) => {
            // reject(err);
          });
      });
    },
    setCollection(context, payload) {
      context.commit('SET_COLLECTION', payload)
    }
  },
  getters: {
    getModel: (state) => state.model,
    getErrors: (state) => state.errors,
    getCollection: (state) => state.collection,
    getCompterendu: (state) => state.compterendu,
    getIsGuest: (state) => state.isGuest,
  },
};
