import { axios } from '../services/axios.js';
export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: null,
      numberOfGrids: null,
      createdAt: null,
      updatedAt: null,
      grids: [],
    },
    campagneSelected: {
      id: null,
      name: null,
      grids: [],
      gridSelected: null,
    },
    campagneInfo: {},
    collection: [],
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      page: 1,
    },
    filter: {
      keyword: '',
      startDate: null,
      endDate: null,
      withPagination: 0,
      sortOrder: null,
      sortKey: null,
      trashed: false,
    },
    errors: [],
    isFetchingData: false,
    statByCampagne: [],
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    DELETE(state, payload) {
      state.collection = state.collection.filter(
        (configItem) => configItem.campagneId !== payload,
      );
    },
    UPDATE(state, payload) {
      let index = state.collection.findIndex((el) => el.id === payload.id);
      state.collection.splice(index, 1, payload);
    },

    SET_STAT_BY_CAMPAGNE(state, payload) {
      state.statByCampagne = payload;
    },
    RESET_STAT_BY_CAMPAGNE(state) {
      state.statByCampagne = [];
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    SET_CAMPAGNE_SELECTED(state, payload) {
     
      state.campagneSelected = payload;
    },
    RESET_CAMPAGNE_SELECTED(state) {
      state.campagneSelected = {
        id: null,
        name: null,
      };
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: null,
        numberOfGrids: null,
        createdAt: null,
        updatedAt: null,
        grids: [],
      };
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_IS_FETCHING(state, payload) {
      state.isFetchingData = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_CAMPAGNE_INFO(state, payload) {
      state.campagneInfo.campagneId = payload.campagneId;
    
      state.campagneInfo.campagneName = payload.campagneName;
    
      state.campagneInfo.grids = payload.grids;
  
      state.campagneInfo.gridSelected = payload.gridSelected;
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 10,
        page: 1,
      };
    },
    ADD_GRID_TO_CAMPAGNE(state, payload) {
    
      if (!state.campagneInfo.grids) {
        state.campagneInfo.grids = [];
      }
      state.campagneInfo.grids.push(payload.grid);   
    },
    SET_GRID_ID_TO_NULL(state) {
      state.campagneInfo.gridSelected = null
    },
  },
  actions: {

    updateCampagneSelectFromWhoami(id){
      context.commit('SET_CAMPAGNE_SELECTED', id);

    },
    async createCampaign(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .post('/campagne', context.state.model, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            //reject(err);
          });
      });
    },
    async getData(context) {
      context.commit('SET_IS_FETCHING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/campagne', config)
          .then(async (res) => {
            context.commit('SET_IS_FETCHING', false);
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_IS_FETCHING', false);
          });
      });
    },
    async getNumberGrid(context) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/numberGrid', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);

            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            resolve(true);
          })
          .catch((err) => {
            //reject(err);
          });
      });
    },
    async updateCampagne(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.page,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .put(
            '/campagne/' + context.state.model.id,
            context.state.model,
            config,
          )
          .then(async (res) => {
            await context.commit('UPDATE', res.data.data.data);
            if (context.state.model.id == context.state.campagneSelected.id) {
              context.commit('SET_CAMPAGNE_SELECTED', {
                id: context.state.model.id,
                name: context.state.model.name,
              });
            }
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            // reject(true);
          });
      });
    },
    async deleteCampaign(context, payload) {
      context.commit('DELETE', payload);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
          data: { ids: payload },
        };
        axios
          .delete('/campagne/delete', config)
          .then(async (res) => {
            await context.commit('DELETE', payload);
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
            //reject(err);
          });
      });
    },

    async duplicateCampaign(context, id) {
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post(`/duplicate-campagne/${id}`, null, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
          });
      });
    },

    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    setCampagneSelected(context, payload) {
      context.commit('SET_CAMPAGNE_SELECTED', payload);
    },
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
    resetCampagneSelected(context) {
      context.commit('RESET_CAMPAGNE_SELECTED');
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    setCollection(context, payload) {
      context.commit('SET_COLLECTION', payload);
    },
    setGridIdToNull(context) {
      context.commit('SET_GRID_ID_TO_NULL');
    },
    async addCampgneSelectedToUserWithStat(context, payload) {
      return await new Promise((resolve, reject) => {
        const today = new Date();
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(today.getDate() - 7);
        const formatDateForCompare = (date) => {
          const day = ('0' + date.getDate()).slice(-2);
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };
        const startDateForCompare = formatDateForCompare(oneWeekAgo);
        const endDateForCompare = formatDateForCompare(today);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
          params: {
            startDate: context.state.filter?.startNewDate? context.state.filter?.startNewDate: startDateForCompare,
            endDate: context.state.filter?.endNewDate? context.state.filter?.endNewDate :endDateForCompare,
            campagneId: payload?.campagneId,
            gridId: payload?.gridSelected?.id,
          },
        };
        axios
          .get('scoreByCampagne', config)
          .then(async (res) => {
            context.commit('SET_STAT_BY_CAMPAGNE', res.data.data.data);
          //  context.commit('SET_CAMPAGNE_INFO',res.data.data.data)
            resolve(res.data.data.data);
          })
          .catch((err) => {});
      });
    },
  
    async setGridSelectedToNull(context, payload) {
      return await new Promise((resolve, reject) => {
        let params= {
          gridIds: payload,
        }
        const config = {
          
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .post('users/archiveSeleteGridSelected',params, config)
          .then(async (res) => {
            const gridSelected = context.state.campagneInfo.gridSelected.id;
            const updatedGridIds = res.data.data.data;
            if (gridSelected && updatedGridIds.includes(gridSelected)) {
              context.commit('SET_GRID_ID_TO_NULL');
            }
            resolve(res.data.data.data);
          })
          .catch((err) => {});
      });
    },
  },
  getters: {
    getIsFetching: (state) => state.isFetchingData,
    getModel: (state) => state.model,
    getCampagneSelected: (state) => state.campagneSelected,
    getPagination: (state) => state.pagination,
    getCollection: (state) => state.collection,
    getFilter: (state) => state.filter,
    getErrors: (state) => state.errors,
    getCampagneInfo: (state) => state.campagneInfo,
    getstatByCampagne: (state) => state.statByCampagne,
  },
};