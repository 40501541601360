export const getAudioDurationInSecondsFromUrl = async (url) => {
    return await new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.addEventListener('loadedmetadata', () => {
            resolve(audio.duration);
        });
        audio.addEventListener('error', () => {
            //reject('Error loading audio');
            resolve(0);

        });
     

        audio.src = url;
    });
}