import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state: {
    model: {
      id: null,
      name: '',
      emotion:false,
      notes: [
        {
          id: uuidv4(),
          name: 'Conforme',
          score: '+5',
          status: 1,
          percentage_min: 80,
          percentage_max: 100,
          meaning:'',
          not_applicable: false

        },
        {
          id:  uuidv4(),
          name: 'Partiellement conforme',
          score: '-1',
          status: 1,
          percentage_min: 0,
          percentage_max: 0,
          meaning:'',
          not_applicable: false

        },
        {
          id:  uuidv4(),
          name: 'Non conforme',
          score: '0',
          status: 1,
          percentage_min: 0,
          percentage_max: 0,
          meaning:'',
          not_applicable: false

        },
        {
          id:  uuidv4(),
          name: 'Situation inacceptable',
          score: '0',
          status: 1,
          percentage_min: 60,
          percentage_max: 80,
          meaning:'',
          not_applicable: false

        },
        {
          id:  uuidv4(),
          name: 'Non applicable',
          score: '0',
          status: 1,
          percentage_min: '',
          percentage_max: '',
          meaning:'',
          not_applicable: true

        },
      ],
      categoryId: null,
      withIa: false,
    },
    collection: [],
    errors: null,
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    UPDATE(state, payload) {
      let index = state.model.findIndex((el) => el.id === payload.id);
      state.model.splice(index, 1, payload);
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        name: '',
        notes: [
          {
            id: uuidv4(),
            name: 'Conforme',
            score: '+5',
            status: 1,
            percentage_min: 80,
            percentage_max: 100,
            not_applicable: false
          },
          {
            id: uuidv4(),
            name: 'Partiellement conforme',
            score: '-1',
            status: 1,
            percentage_min: 0,
            percentage_max: 0,
            not_applicable: false
          },
          {
            id: uuidv4(),
            name: 'Non conforme',
            score: '0',
            status: 1,
            percentage_min: 0,
            percentage_max: 0,
            not_applicable: false
          },          
          {
            id:  uuidv4(),
            name: 'Situation inacceptable',
            score: '0',
            status: 1,
            percentage_min: 60,
            percentage_max: 80,
            not_applicable: false
          },
          {
            id: uuidv4(),
            name: 'Non applicable',
            score: '0',
            status: 1,
            percentage_min:'' ,
            percentage_max: '',
            not_applicable: true
          },

        ],
        categoryId: null,
        withIa: false,
        emotion:false,
      };
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    UPDATE_CATEGORY_ID(state, payload) {
      state.collection = payload;
    },
    UPDATE_NOTE(state, payload) {
      const noteIndex = state.model.notes.findIndex(
        (elm) => elm.id === payload.id,
      );
      if (noteIndex !== -1) {
        state.model.notes[noteIndex] = payload;
      }
    },
    DELETE(state) {
      state.collection = [];
      state.model = {
        id: null,
        name: '',
        notes: [
          {
            id: uuidv4(),
            name: 'Conforme',
            score: '+5',
            status: 1,
            percentage_min: 80,
            percentage_max: 100,
            not_applicable: false

          },
          {
            id: uuidv4(),
            name: 'Partiellement conforme',
            score: '-1',
            status: 1,
            percentage_min: 0,
            percentage_max: 0,
            not_applicable: false

          },
          {
            id: uuidv4(),
            name: 'Non conforme',
            score: '0',
            status: 1,
            percentage_min: 0,
            percentage_max: 0,
            not_applicable: false

          },
        {
          id:  uuidv4(),
          name: 'Situation inacceptable',
          score: '0',
          status: 1,
          percentage_min: 60,
          percentage_max: 80,
          not_applicable: false

        },
        {
          id: uuidv4(),
          name: 'Non applicable',
          score: '0',
          status: 1,
          percentage_min: 60,
          percentage_max: 80,
          not_applicable: true
        },
        ],
        categoryId: null,
        type: '',
        order: '',
        withIa: false,
        emotion:false,
        typeEmotion: '',
      };
    },
  },

  actions: {
    setItemModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    resetItemModel(context) {
      context.commit('RESET_MODEL');
    },
    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    addItem(context, payload) {
      context.commit('ADD', payload);
    },
    updateCategIdInItem(context, payload) {
      let updatedData = context.state.collection.map((item) => {
        return {
          ...item,
          categoryId: payload,
        };
      });

      context.commit('UPDATE_CATEGORY_ID', updatedData);
      return Promise.resolve(updatedData);
    },
    deleteItem(context) {
      context.commit('DELETE');
    },
    updateNote(context, payload) {
      context.commit('UPDATE_NOTE', payload);
    },
    updateItem(context, payload) {
      context.commit('UPDATE', payload);
    },
  },
  getters: {
    getItemModel: (state) => state.model,
    getItemCollection: (state) => state.collection,
    getErrors: (state) => state.errors,
  },
};
