//import { axios } from '../services/axios.js';
export default {
    namespaced: true,
    state: {
        routeName: ''
    },
    mutations: {
      SET_ROUTE_NAME(state, payload) {
        state.routeName = payload ;
      },
    },
    actions: {
        setRouteName(context, payload){
            context.commit('SET_ROUTE_NAME', payload)
        }
    },
    getters: {
        getRouteName: (state) => state.routeName,
    },
  };
  