import { getAudioDurationInSecondsFromUrl } from '@/helpers/audio.js';
import { axios } from '../services/axios.js';

export default {
  namespaced: true,
  state: {
    model: {
      recordName: '',
      linkRegistration: '',
      status: '',
      account: '',
      creationDate: '',
      segments: [],
    },
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      page: 1,
    },
    filter: {
      status: [],
      keyword: '',
      withPagination: 0,
      sortOrder: null,
      sortKey: null,
    },
    collection: [],
    errors: [],
    isFetchingData: false,
  },
  mutations: {
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    SET_IS_FETCHING(state, payload) {
      state.isFetchingData = payload;
    },
    SET_SEGMENTATION(state, payload) {
      payload.forEach((elm) => {

        state.model.segments.push(elm.segmentation);
      });
    },
    UPDATE(state, payload) {
      let index = state.model.segments.findIndex(segmentation => segmentation.id === payload.id);
      state.model.segments.splice(index, 1, payload);
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },
  },

  actions: {
    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },
    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },
    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },
    setSegmentation(context, payload) {
      context.commit('SET_SEGMENTATION', payload);
    },
    async getData(context) {
      context.commit('SET_IS_FETCHING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/trainingRecord/all', config)
          .then(async (res) => {
         
            let records = []
            
            for (let item of res.data.data.data) {
          
              await getAudioDurationInSecondsFromUrl(item.url)
                .then(duration => {
                  records.push({
                    ...item,
                    ['duration']: duration
                  })
                }).catch(()=>{
               
                  records.push({
                    ...item,
                    ['duration']: 0
                  })
                })
            }
          

            await context.commit('SET_COLLECTION', records);
            await context.commit('SET_SEGMENTATION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            context.commit('SET_IS_FETCHING', false);
            resolve(res.data.data);
          })
          .catch((err) => {
            alert(1)
            context.commit('SET_IS_FETCHING', false);
          });
      });
    },
    async updateSegmentationTraining(context, payload) {
      let reviewedSpeatch = { reviewedSpeatch: payload.speechToReview };
      return await new Promise((resolve) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .put(
            '/trainingRecord/segmentaion/' + payload.id,
            reviewedSpeatch,
            config,
          )
          .then(async (res) => {
            await context.commit('UPDATE', res.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            // context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch(() => {
            // if (err.response.status === 422 || err.response.status ==400 ) {
            // }
          });
      });
    },
  },
  getters: {
    getModel: (state) => state.model,
    getCollection: (state) => state.collection,
    getPagination: (state) => state.pagination,
    getFilter: (state) => state.filter,
    getIsFetching: (state) => state.isFetchingData,
  },
};
