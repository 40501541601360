import Vue from 'vue';
import store from './../../store';
import { axios } from '../services/axios.js';
export default {
  namespaced: true,
  state: {
    user: null,
    isLogged: false,
    resetPasswordStatus: null,
    errors: [],
    collection: [],
    model: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      role: null,
      createdAt: null,
      updatedAt: null,
    },
    userSelected: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      role: null,
      createdAt: null,
      updatedAt: null,
    },
    pagination: {
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 10,
      page: 1,
    },
    filter: {
      keyword: '',
      withPagination: 0,
      trashed: false,
    },
    isFetchingData: false,
    isFetchingDataLoading: false,
    sortOrder: null,
    sortKey: null,
  },
  mutations: {
    ADD(state, payload) {
      state.collection = [payload, ...state.collection];
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_PAGINATION(state, payload) {
      // this mutation will change the pagination state values
      state.pagination = payload;
    },
    UPDATE(state, payload) {
      let index = state.collection.findIndex((el) => el.id === payload.id);
      state.collection.splice(index, 1, payload);
    },

    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    LOGOUT_USER(state) {
      state.user = null;
    },
    SET_TOKEN_USER(state, newValue) {
      localStorage.setItem('auth', newValue);
    },
    SET_LOGGED(state, newValue) {
      state.isLogged = newValue;
    },
    SET_RESET_PASSWORD_STATUS(state, message) {
      state.resetPasswordStatus = message;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },

    SET_IS_FETCHING(state, payload) {
      state.isFetchingData = payload;
    },

    RESET_PASSWORD_MODEL(state) {
      state.model = {
        errors: [],
      };
    },
    SET_GRID_USER(state, payload) {
      state.user.selectedGridName = payload.gridName;
    },
    SET_CAMPAGNE_USER(state, payload) {
      state.user.selectedCampagneId = payload.campagneId;
      state.user.selectedCampagneName = payload.campagneName;
      state.user.selectedGridId = payload.gridSelectedId;
    },
    SET_IS_LOADING(state, payload) {
      state.isFetchingDataLoading = payload;
    },
    SET_FILTER(state, payload) {
      state.filter = payload;
    },
    DELETE(state, payload) {
      state.collection = state.collection.filter(
        (configItem) => configItem.id !== payload,
      );
    },
    SET_MODEL(state, payload) {
      state.model = payload;
    },

    SET_USER_SELECTED(state, payload) {
      state.userSelected = payload;
    },

    RESET_USER_SELECTED(state) {
      state.userSelected = {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        role: null,
      };
    },
    RESET_MODEL(state) {
      state.model = {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        role: null,
      };
    },
  },
  actions: {
    connectUser(context, payload) {
      context.commit('UPDATE_USER', payload);
    },
    register(context, payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('auth'),
          },
        };
        axios
          .post('register', payload, config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    Whoami({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/whoami', {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('auth'),
            },
          })
          .then((response) => {
            store.dispatch('detailsCalibrage/setIsGuest', false);
            commit('UPDATE_USER', response.data.data.data);
            localStorage.setItem('role', response.data.data.data.role);

            localStorage.setItem('user', JSON.stringify(this.state.user.user));
            commit('SET_LOGGED', true);
            resolve(response.data.data.data);
          })
          .catch((e) => {
            commit('SET_LOGGED', false);
            localStorage.clear();
            // router.push('/login');
            reject(e);
          });
      });
    },
    logIn({ commit }, { email, password, remember_me } = {}) {
      let params = {
        email: email,
        password: password,
        remember_me: remember_me,
      };
      return new Promise((resolve, reject) => {
        axios
          .post('/login', params, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          })
          .then((response) => {
            store.dispatch('detailsCalibrage/setIsGuest', false);

            commit('SET_LOGGED', true);
            commit('UPDATE_USER', response.data.user);
            commit('SET_TOKEN_USER', response.data.token);

            localStorage.setItem('role', response.data.user.role);
            localStorage.setItem('user', JSON.stringify(this.state.user.user));
            /* eslint-disable */
            const app = Vue.observable({
              theme: 'dark',
            });
            /* eslint-enable */
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    logInAsAnotherUser(context) {
      const user = localStorage.getItem('user');
      const parsedUser = user ? JSON.parse(user) : null;
      const idOldUser = localStorage.getItem('IdOldUser');

      let params = {
        id: context.state.model.id || idOldUser,
        currentUser: parsedUser ? parsedUser.id : null,
      };
      return new Promise((resolve, reject) => {
        axios
          .post('/logInAsAnotherUser', params, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          })
          .then((response) => {
            const fromAnotherAccount = Boolean(idOldUser);

            // Clear local storage and set new items
            localStorage.clear();
            if (!fromAnotherAccount && parsedUser) {
              localStorage.setItem('IdOldUser', parsedUser.id);
            }
            localStorage.setItem('role', response.data.user.role);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            // Commit new user and token to store
            context.commit('UPDATE_USER', response.data.user);
            context.commit('SET_TOKEN_USER', response.data.token);

            // Redirect to home page
            window.location.href = '/';

            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    //GetUsers
    async Users(context) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/getUsers', config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            resolve(res.data.data);
          })
          .catch((err) => {
            //reject(err);
          });
      });
    },

    async getData(context) {
      context.commit('SET_IS_LOADING', true);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: context.state.pagination.current_page,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .get('/getUsers', config)
          .then(async (res) => {
            context.commit('SET_IS_LOADING', false);
            await context.commit('SET_COLLECTION', res.data.data.data);
            if (res.data.pagination) {
              await context.commit('SET_PAGINATION', res.data.pagination);
            }
            resolve(true);
          })
          .catch((err) => {
            context.commit('SET_IS_LOADING', false);
          });
      });
    },

    async logout({ commit }) {
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      };
      return await new Promise((resolve, reject) => {
        axios
          .post('/logout', null, config)
          .then(() => {
            commit('SET_LOGGED', false);
            commit('LOGOUT_USER');
            localStorage.clear();
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    forgetPassword({ commit }, email) {
      const config = {
        params: {
          email: email,
        },
      };

      return new Promise((resolve, reject) => {
        axios
          .post('/forget-password', null, config, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          })
          .then(() => {
            commit(
              'SET_RESET_PASSWORD_STATUS',
              'Un e-mail de réinitialisation de mot de passe a été envoyé.',
            );
            resolve(true);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              commit('SET_ERRORS', error.response.data.errors);
            }
            reject(error);
          });
      });
    },
    async resetPassword({ commit }, payload) {
      const config = {
        params: {
          password: payload.password,
          password_confirmation: payload.password_confirmation,
          token: payload.token,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .post('/reset-password', null, config, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              commit('SET_ERRORS', error.response.data.errors);
            }
            if (error.response.status === 400) {
              commit('SET_ERRORS', error.response.data.message);
            }
            reject(error);
          });
      });
    },

    async welcomeJoin({ commit }, payload) {
      const config = {
        params: {
          password: payload.password,
          password_confirmation: payload.password_confirmation,
          token: payload.token,
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`/welcome/${payload.token}`, payload, config, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              commit('SET_ERRORS', error.response.data.errors);
            }
            if (
              error.response.status === 400 ||
              error.response.status === 404
            ) {
              // router.push('/login'); // Redirection vers la page de connexion en cas de token non valide
            }
            reject(error);
          });
      });
    },

    setErrors(context, payload) {
      context.commit('SET_ERRORS', payload);
    },
    setUser(context, payload) {
      context.commit('UPDATE_USER', payload);
    },

    async updateUserAdmin(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };
        axios
          .put(
            '/update/user/' + context.state.model.id,
            context.state.model,
            config,
          )
          .then(async (res) => {
            await context.commit('UPDATE', res.data.data.data);
            if (context.state.model.id == context.state.userSelected.id) {
              context.commit('SET_USER_SELECTED', {
                id: context.state.model.id,
                name: context.state.model.name,
              });
            }
            await context.commit('RESET_MODEL');
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            // reject(true);
          });
      });
    },

    setGuest(context, payload) {
      context.commit('SET_IS_GUEST', payload);
    },
    async updateProfil(context, payload) {
      context.commit('SET_ERRORS', null);
      let id = context.state.user.id;
      return await new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .put('/update/user/' + id, payload, config)
          .then(async (res) => {
            context.commit('UPDATE_USER', res.data.data.data);
            resolve(true);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
          });
      });
    },
    resetPasswordModel(context) {
      context.commit('RESET_PASSWORD_MODEL');
    },

    joinUs({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/join-us/${token}`)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              commit('SET_ERRORS', error.response.data.errors);
            }
            reject(error);
          });
      });
    },

    async checkEmailExistance(context, payload) {
      return await new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/verify-email', payload, config)
          .then(async (res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async updateUser(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .put('/update/user/' + payload.id, payload.data, config)
          .then(async (res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async deleteUser(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
          params: {
            forceDelete: payload.forceDelete,
          },
        };
        axios
          .delete('/delete/user/' + payload.id, config)
          .then(async (res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Nouvelle deleted user with trashed
    async deletedUser(context, payload) {
      context.commit('DELETE', payload);
      return await new Promise((resolve, reject) => {
        const config = {
          params: {
            per_page: context.state.pagination.per_page,
            page: 1,
            ...context.state.filter,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
          data: { ids: payload },
        };
        axios
          .delete('/user/delete', config)
          .then(async (res) => {
            await context.commit('DELETE', payload);
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.message);
            }
            //reject(err);
          });
      });
    },

    setModel(context, payload) {
      context.commit('SET_MODEL', payload);
    },

    async createAdminUser(context) {
      context.commit('SET_ERRORS', null);
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
          },
        };

        axios
          .post('/store-admin', context.state.model, config)
          .then(async (res) => {
            await context.commit('SET_COLLECTION', res.data.data.data);
            await context.commit('SET_PAGINATION', res.data.pagination);
            await context.commit('RESET_MODEL');
            resolve(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 422) {
              context.commit('SET_ERRORS', err.response.data.errors);
            }
            //reject(err);
          });
      });
    },

    async inviteUserTojoinScriib(context, payload) {
      return await new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/rejoind-scribb/' + payload, null, config)
          .then(async (res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //verify token before join use
    verifyToken({ commit }, { token }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/verify-token-join/' + token, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            resolve(response.data.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    //verify email exist or not
    checkEmail(context, payload) {
      let params = {
        email: payload.email,
      };

      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            Accept: 'application/json',
          },
        };
        axios
          .post('/checkEmail', params, config)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    setPagination(context, payload) {
      context.commit('SET_PAGINATION', payload);
    },

    setFilter(context, payload) {
      context.commit('SET_FILTER', payload);
    },

    setUserSelected(context, payload) {
      context.commit('SET_USER_SELECTED', payload);
    },
    resetUserSelected(context) {
      context.commit('RESET_USER_SELECTED');
    },
    resetModel(context) {
      context.commit('RESET_MODEL');
    },
  },

  getters: {
    getUser: function (state) {
      return state.user;
    },
    getFirstName: function (state) {
      return `${state.user.first_name}`;
    },
    getFullUser: function (state) {
      return state.user;
    },
    getPseudo: function (state) {
      return state.user.pseudo;
    },
    getToken: function (state) {
      return state.user.token;
    },
    getRole: function (state) {
      return state.user.role;
    },
    getIdUser: function (state) {
      return state.user.id;
    },
    getCallCenterId: function (state) {
      return state.user.call_center_id;
    },
    getTeamId: function (state) {
      return state.user.team_id;
    },
    isLogged: (state) => state.isLogged,
    getErrors: (state) => state.errors,
    getCollection: (state) => state.collection,
    getPagination: (state) => state.pagination,
    getFilter: (state) => state.filter,
    getState: (state) => state,
    getModel: (state) => state.model,
    getUserSelected: (state) => state.userSelected,
  },
};
