var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aside',{staticClass:"sidebar"},[_vm._m(0),_c('div',{},[_c('ul',{staticClass:"menu_principal scroll"},[_c('li',{class:{ 'router-link-active': _vm.path === '/dashboard' }},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('i',{staticClass:"icon-dashboard mr-2"}),_vm._v(" Dashboard ")])],1),_c('ul',[_c('li',{staticClass:"nav_accordion",class:{ 'router-link-active': _vm.areStringsInRoute },attrs:{"data-toggle":"teams__"},on:{"click":_vm.close_accordion}},[_vm._m(1)]),_c('ul',{staticClass:"list_menu_accordion",class:{ 'block-open': _vm.areStringsInRoute },attrs:{"id":"teams__"}},_vm._l((_vm.visibleLinks),function(link,index){return _c('li',{key:index,class:{ 'bold-font': _vm.path === link.to }},[_c('router-link',{attrs:{"to":link.to}},[_vm._v(_vm._s(link.text))])],1)}),0)]),_vm._l((_vm.visibleLinksSecondary),function(link,index){return _c('li',{key:index,class:{ 'router-link-active': _vm.path === link.to }},[_c('router-link',{attrs:{"to":link.to}},[_c('i',{class:link.iconClass}),_vm._v(" "+_vm._s(link.text)+" ")])],1)})],2),_c('ul',{staticClass:"menu_profil"},[_c('li',[_c('a',{on:{"click":_vm.toggleDrawer}},[_c('i',{staticClass:"icon-profil mr-2"}),_vm._v(" Profil ")])]),(
            _vm.$globals.hasAuthorities('settings', 'navigate', _vm.role) &&
            !_vm.isGuest &&
            !_vm.isLoading
          )?_c('li',{class:{ 'router-link-active': _vm.path === '/settings' }},[_c('router-link',{attrs:{"to":"/settings"}},[_c('i',{staticClass:"icon-settings mr-2"}),_vm._v(" Paramètres ")])],1):_vm._e(),_c('li',[_c('a',{on:{"click":_vm.handleLogout}},[_c('i',{staticClass:"icon-logout mr-2"}),_vm._v(" Déconnexion ")])])])])]),_c('DrawerProfil',{attrs:{"isOpened2":_vm.showDrawer},on:{"close":function($event){_vm.showDrawer = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/images/logo/logo_dashboard.svg"),"alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"flex align-items"},[_c('i',{staticClass:"icon-teams mr-2"}),_vm._v(" Administrations "),_c('i',{staticClass:"icon-down ml-2"})])
}]

export { render, staticRenderFns }