export default {
  defaultModel: {
    id: null,
    hermesId: null,
    firstName: null,
    lastName: null,
    callCenterId: null,
    teamId: null,
    roleId: null,
    topFlop: 'top',
    inDashboard:false
  },
  model: {
    id: null,
    hermesId: null,
    firstName: null,
    lastName: null,
    callCenterId: null,
    teamId: null,
    roleId: null,
    topFlop: 'top',
    inDashboard:false
  },
  collection: [],
  filtredCollection: [],
  collectionWithoutTeam: [],
  topFlopCollection: [],
  statCollection: [],
  moreDetailsCollection: [],
  pagination: {
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 10,
    page: 1,
    inTeam: false,
  },
  filter: {
    keyword: '',
    startDate: null,
    endDate: null,
    callcenter: [],
    team: [],
    withPagination: 1,
    startNewDate: null,
    endNewDate: null,
    startOldDate: null,
    endOldDate: null,
    sortOrder: null,
    sortKey: null,
    trashed: false,
    callCenters: [],
    agents: [],
    teams: [],
  },
  errors: null,
  isFetchingData: false,
  isFetchingDetails: false,
  cancel: null,
};
