<template>
  <div class="transcription-notification" @click="handleClick">
    <p class="transcription-notification-message">
      Transcription
      <strong>d'audio #{{ notification.data.record }}</strong>
      est terminé avec succées
    </p>
    <span class="notification-time">
      {{ $globals.getTimeAgo(notification.created_at) }}
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["notification"],
  methods: {
    ...mapActions("record", [
      "setActiveRecord",
      "setPagination",
      "getData",
      "setNavigationFrom",
    ]),
    ...mapActions("notifications", ["markAsread"]),
    async handleClick() {
      if (this.$route.path == "/records") {
        if (this.records.find((x) => x.id === this.notification.data.record)) {
          this.setActiveRecord(
            this.records.find((x) => x.id == this.notification.data.record)
          );
        } else {
          do {
            this.setPagination({
              ...this.getPagination,
              ["current_page"]: this.getPagination.current_page + 1,
            });
            await this.getData();
          } while (!this.records.find((x) => x.id === this.notification.data.record));
          this.setActiveRecord(
            this.records.find((x) => x.id == this.notification.data.record)
          );
        }
      } else {
        this.setNavigationFrom("notification");
        this.$router.push("/records");
        if (this.records.find((x) => x.id === this.notification.data.record)) {
          this.setActiveRecord(
            this.records.find((x) => x.id == this.notification.data.record)
          );
        } else {
          let pas = 0;
          do {
            this.setPagination({
              ...this.getPagination,
              ["current_page"]: this.getPagination.current_page + pas,
            });
            pas++;
            await this.getData();
          } while (!this.records.find((x) => x.id === this.notification.data.record));
          this.setActiveRecord(
            this.records.find((x) => x.id == this.notification.data.record)
          );
        }
        this.setNavigationFrom("");
      }
      if (this.notification.read_at == null) {
        this.markAsread(this.notification.id);
      }
    },
  },
  computed: {
    ...mapGetters("record", ["getActiveRecord", "getCollection", "getPagination"]),
    activeRecord() {
      return this.getActiveRecord;
    },
    records() {
      return this.getCollection;
    },
  },
};
</script>


